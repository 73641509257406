import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GhostContentAPI from "@tryghost/content-api";
import { FadeLoader } from "react-spinners";
import SEO from "../SEO";

const api = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_API_URL,
  key: process.env.REACT_APP_GHOST_CONTENT_API_KEY,
  version: "v5.0",
});

export default function DocumentationHome() {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.pages
      .browse({ 
        filter: 'tag:documentation',
        include: 'tags',
        limit: 'all' // Get all documentation pages
      })
      .then((response) => {
        setPages(response);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex-1 flex lg:mt-3 lg:ml-3 lg:mr-3 rounded-lg flex-col h-full pb-10 bg-gradient-to-b from-neutral-50 to-white rounded-t-lg">
        <div className="flex justify-center items-center min-h-screen">
          <FadeLoader color="#4055A8" />
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO title="PodScribe.IO | Documentation" />
      <div className="flex-1 flex flex-col h-full pb-10 bg-white lg:mt-3 lg:ml-3 lg:mr-3 lg:rounded-t-lg border border-neutral-100 shadow-sm">
        <div className="bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div>
              <h1 className="text-2xl font-bold text-primary-600">Documentation</h1>
              <p className="text-sm text-neutral-500 mt-1">
                Learn how to use PodScribe.IO with our comprehensive documentation.
              </p>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300">
          <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {pages.length === 0 ? (
              <div className="text-center py-12">
                <p className="text-lg text-neutral-700">No documentation pages found.</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                {pages.map((page) => (
                  <Link 
                    key={page.id}
                    to={`/documentation/${page.slug}`}
                    className="bg-white rounded-lg shadow-sm border border-neutral-100 overflow-hidden hover:shadow-md transition-all duration-200"
                  >
                    {page.feature_image && (
                      <div className="w-full h-40 overflow-hidden">
                        <img 
                          src={page.feature_image} 
                          alt={page.title} 
                          className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                        />
                      </div>
                    )}
                    <div className="p-6">
                      <h3 className="text-xl font-semibold text-primary-600 mb-2">{page.title}</h3>
                      <p className="text-neutral-600 line-clamp-3">{page.excerpt || page.meta_description}</p>
                      <div className="mt-4 inline-flex items-center text-primary-600 hover:text-primary-800 font-medium">
                        Read More
                        <svg className="ml-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M12.293 9l-4.647-4.646a.5.5 0 01.707-.708l5 5a.5.5 0 010 .708l-5 5a.5.5 0 11-.707-.708L12.293 11H5.5a.5.5 0 010-1h6.793z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
