import React, { useState } from 'react';
import { SearchIcon, XCircleIcon } from '@heroicons/react/outline';

const QueryInput = ({ onSubmit, disabled, placeholder }) => {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      onSubmit(query);
      setQuery('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative">
      <div className="relative group">
        <div className="absolute -inset-0.5 bg-gradient-to-r from-primary-600 to-secondary-600 rounded-xl blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200" />
        <div className="relative">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            disabled={disabled}
            placeholder={placeholder || "Ask anything about your podcasts..."}
            className="block w-full rounded-xl border-0 py-4 pl-5 pr-24 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 disabled:bg-neutral-50 disabled:text-neutral-500 disabled:ring-neutral-200 sm:text-sm sm:leading-6"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-4 space-x-2">
            {query && (
              <button
                type="button"
                onClick={() => setQuery('')}
                className="p-1 text-neutral-400 hover:text-neutral-600 transition-colors"
              >
                <XCircleIcon className="h-5 w-5" />
              </button>
            )}
            <button
              type="submit"
              disabled={disabled || !query.trim()}
              className={`p-1 ${
                disabled || !query.trim() 
                  ? 'text-neutral-300 cursor-not-allowed' 
                  : 'text-neutral-400 hover:text-primary-600 transition-colors'
              }`}
            >
              <SearchIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default QueryInput;
