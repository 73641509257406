import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XIcon,
  RefreshIcon,
  CheckCircleIcon,
  XCircleIcon,
  ClockIcon,
  InformationCircleIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import moment from "moment";
import useLibraryData from "../../hooks/useLibraryData";

const JobStatusModal = ({ isOpen, onClose, getUserJobs }) => {
  const { cleanUserJobs } = useLibraryData();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalJobs, setTotalJobs] = useState(0);
  const [expandedJobId, setExpandedJobId] = useState(null);
  const [cleaning, setCleaning] = useState(false);
  const [cleanMessage, setCleanMessage] = useState(null);

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await getUserJobs();

      if (response.success) {
        setJobs(response.jobs);
        setTotalJobs(response.count);
      } else {
        console.error("Failed to fetch jobs", response.error);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchJobs();
    }
  }, [isOpen]);

  // Clean completed and failed jobs
  const cleanJobs = async () => {
    if (cleaning) return;

    const completedAndFailedJobs = jobs.filter((job) => job.status === "completed" || job.status === "failed");

    if (completedAndFailedJobs.length === 0) {
      setCleanMessage({ type: "info", text: "No completed or failed jobs to clean." });
      setTimeout(() => setCleanMessage(null), 3000);
      return;
    }

    if (
      !window.confirm(`Are you sure you want to remove ${completedAndFailedJobs.length} completed and failed jobs?`)
    ) {
      return;
    }

    setCleaning(true);
    setCleanMessage({ type: "progress", text: "Cleaning jobs..." });

    try {
      // Collect all job IDs that should be deleted
      const jobIdsToDelete = completedAndFailedJobs.map((job) => job.id);

      // Use the cleanUserJobs function from the hook
      const result = await cleanUserJobs(jobIdsToDelete);

      if (result.success) {
        setCleanMessage({
          type: "success",
          text: `Successfully cleaned ${result.cleanedCount} jobs.`,
        });

        // Refresh the jobs list after cleaning
        await fetchJobs();
      } else {
        throw new Error(result.error || "Failed to clean jobs");
      }
    } catch (error) {
      console.error("Error cleaning jobs:", error);
      setCleanMessage({
        type: "error",
        text: `Error: ${error.message || "Failed to clean jobs"}`,
      });
    } finally {
      setCleaning(false);
      // Clear the message after 3 seconds
      setTimeout(() => setCleanMessage(null), 3000);
    }
  };

  const getStatusBadge = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-support-green-50 text-support-green-800">
            <CheckCircleIcon className="mr-1 h-3 w-3" aria-hidden="true" />
            Completed
          </span>
        );
      case "in_progress":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-support-blue-50 text-support-blue-800">
            <ClockIcon className="mr-1 h-3 w-3 animate-pulse" aria-hidden="true" />
            In Progress
          </span>
        );
      case "queued":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-support-yellow-50 text-support-yellow-800">
            <ClockIcon className="mr-1 h-3 w-3" aria-hidden="true" />
            Queued
          </span>
        );
      case "failed":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-support-red-50 text-support-red-800">
            <XCircleIcon className="mr-1 h-3 w-3" aria-hidden="true" />
            Failed
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-neutral-100 text-neutral-700">
            {status}
          </span>
        );
    }
  };

  const formatJobType = (type) => {
    // Convert snake_case to Title Case with spaces
    return type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const toggleJobDetails = (jobId) => {
    setExpandedJobId(expandedJobId === jobId ? null : jobId);
  };

  // Helper function to handle timezone conversion
  const formatDateTime = (dateString) => {
    if (!dateString) return "—";
    return moment.utc(dateString).local().format("MMMM D, YYYY h:mm A");
  };

  const formatRelativeTime = (dateString) => {
    if (!dateString) return "—";
    return moment.utc(dateString).local().fromNow();
  };

  // Add this helper function near other formatting functions
  const truncateTitle = (title, maxLength = 50) => {
    if (!title) return "—";
    return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
  };

  // Helper to render the clean message notification
  const renderCleanMessage = () => {
    if (!cleanMessage) return null;

    const messageStyles = {
      progress: "bg-primary-50 text-primary-800 border-primary-200",
      success: "bg-support-green-50 text-support-green-800 border-support-green-200",
      error: "bg-support-red-50 text-support-red-800 border-support-red-200",
      info: "bg-support-blue-50 text-support-blue-800 border-support-blue-200",
    };

    const messageIcons = {
      progress: <RefreshIcon className="h-4 w-4 mr-2 animate-spin" aria-hidden="true" />,
      success: <CheckCircleIcon className="h-4 w-4 mr-2" aria-hidden="true" />,
      error: <XCircleIcon className="h-4 w-4 mr-2" aria-hidden="true" />,
      info: <InformationCircleIcon className="h-4 w-4 mr-2" aria-hidden="true" />,
    };

    return (
      <div className={`flex items-center px-3 py-2 text-sm rounded-md border ${messageStyles[cleanMessage.type]}`}>
        {messageIcons[cleanMessage.type]}
        {cleanMessage.text}
      </div>
    );
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-neutral-900 bg-opacity-50 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[95vw] max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="absolute right-0 top-0 pr-4 pt-4 z-10">
                  <button
                    type="button"
                    className="rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="flex flex-col h-full">
                  {/* Header section */}
                  <div className="mb-6">
                    <Dialog.Title as="h3" className="text-xl font-display font-semibold leading-6 text-neutral-900">
                      Job Status Dashboard
                    </Dialog.Title>
                    <p className="mt-1 text-sm text-neutral-500">
                      Monitor and track the status of your processing jobs
                    </p>

                    {/* Refresh and Clean controls */}
                    <div className="mt-4 flex justify-between items-center">
                      {/* Clean message notification */}
                      <div className="flex-1 mr-4">{renderCleanMessage()}</div>

                      <div className="flex space-x-2">
                        <button
                          type="button"
                          onClick={cleanJobs}
                          disabled={cleaning || loading}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm 
                                    font-medium rounded-md text-neutral-700 bg-neutral-50 hover:bg-neutral-100 
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                                    transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed">
                          <TrashIcon className={`h-4 w-4 mr-1 ${cleaning ? "animate-pulse" : ""}`} aria-hidden="true" />
                          Clean Jobs
                        </button>

                        <button
                          type="button"
                          onClick={fetchJobs}
                          disabled={loading}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm 
                                    font-medium rounded-md text-primary-700 bg-primary-50 hover:bg-primary-100 
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                                    transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed">
                          <RefreshIcon className={`h-4 w-4 mr-1 ${loading ? "animate-spin" : ""}`} aria-hidden="true" />
                          Refresh Jobs
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Content section */}
                  <div className="flex-1 overflow-y-auto">
                    <div className="py-4">
                      <h2 className="text-sm font-medium text-neutral-500">
                        {loading ? "Loading jobs..." : `Showing ${jobs.length} of ${totalJobs} jobs`}
                      </h2>

                      {loading ? (
                        <div className="flex justify-center my-8">
                          <div className="w-full max-w-lg">
                            <div className="animate-pulse space-y-4">
                              {[...Array(5)].map((_, i) => (
                                <div key={i} className="h-16 bg-neutral-100 rounded-md"></div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : jobs.length === 0 ? (
                        <div className="text-center my-16">
                          <p className="text-neutral-500 text-sm">No jobs found</p>
                        </div>
                      ) : (
                        <div className="mt-4">
                          {/* Job list header - visible on md and larger */}
                          <div className="hidden md:grid md:grid-cols-[2fr_1fr_1fr] lg:grid-cols-[3fr_1fr_1fr] gap-4 px-4 py-3 bg-neutral-50 rounded-t-lg border-t border-x border-neutral-200 text-xs font-medium text-neutral-500 uppercase tracking-wider">
                            <div>Episode Title</div>
                            <div>Status</div>
                            <div className="text-right">Action</div>
                          </div>

                          {/* Responsive job list */}
                          <div className="rounded-lg overflow-hidden border border-neutral-200 shadow-sm divide-y divide-neutral-100">
                            {jobs.map((job) => (
                              <Fragment key={job.id}>
                                <div className="bg-white hover:bg-neutral-50 transition-colors duration-150">
                                  <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr_1fr] lg:grid-cols-[3fr_1fr_1fr] gap-4 p-4">
                                    {/* Episode Title - expanded to take more space */}
                                    <div className="font-medium text-neutral-900">
                                      <span className="md:hidden text-xs text-neutral-500 block">Episode:</span>
                                      <div className="truncate pr-2" title={job.episode_title || "—"}>
                                        {truncateTitle(job.episode_title)}
                                      </div>
                                    </div>

                                    {/* Status */}
                                    <div>
                                      <span className="md:hidden text-xs text-neutral-500 block">Status:</span>
                                      <div className="flex items-center">{getStatusBadge(job.status)}</div>
                                      <div className="text-xs text-neutral-500 mt-1">
                                        {formatRelativeTime(job.created_at)}
                                      </div>
                                    </div>

                                    {/* Action */}
                                    <div className="flex justify-end items-start">
                                      <button
                                        onClick={() => toggleJobDetails(job.id)}
                                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs
                                                font-medium rounded text-primary-700 bg-primary-50 hover:bg-primary-100
                                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                                                transition-colors duration-150">
                                        <InformationCircleIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                                        Details
                                      </button>
                                    </div>
                                  </div>

                                  {/* Expanded job details */}
                                  {expandedJobId === job.id && (
                                    <div className="bg-neutral-50 p-4 border-t border-neutral-100">
                                      <div className="bg-white rounded-lg border border-neutral-200 p-4 shadow-sm">
                                        <h4 className="font-medium text-neutral-900 mb-2">Job Details</h4>
                                        <dl className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2 text-sm">
                                          <div>
                                            <dt className="text-neutral-500">Job ID</dt>
                                            <dd className="mt-1 text-neutral-900">{job.id}</dd>
                                          </div>
                                          <div>
                                            <dt className="text-neutral-500">Job Type</dt>
                                            <dd className="mt-1 text-neutral-900">{formatJobType(job.job_type)}</dd>
                                          </div>
                                          <div>
                                            <dt className="text-neutral-500">Episode ID</dt>
                                            <dd className="mt-1 text-neutral-900">{job.episode_id || "—"}</dd>
                                          </div>
                                          <div>
                                            <dt className="text-neutral-500">Created At</dt>
                                            <dd className="mt-1 text-neutral-900">{formatDateTime(job.created_at)}</dd>
                                          </div>
                                          <div>
                                            <dt className="text-neutral-500">Updated At</dt>
                                            <dd className="mt-1 text-neutral-900">{formatDateTime(job.updated_at)}</dd>
                                          </div>
                                          <div>
                                            <dt className="text-neutral-500">Status</dt>
                                            <dd className="mt-1">{getStatusBadge(job.status)}</dd>
                                          </div>
                                          {job.error && (
                                            <div className="col-span-2">
                                              <dt className="text-neutral-500">Error</dt>
                                              <dd className="mt-1 text-support-red-800 bg-support-red-50 p-2 rounded-md text-xs">
                                                {job.error}
                                              </dd>
                                            </div>
                                          )}
                                        </dl>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Fragment>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default JobStatusModal;
