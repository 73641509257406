import react from "react";
import { Fragment, useState, useRef, useMemo, useEffect, useCallback } from "react";
import useLibraryData from "../../hooks/useLibraryData";
import useUIState from "../../hooks/useUIState";
import { calculateProcessingTime, getRemainingTime } from "../../utils/processingTime";
import { CheckIcon } from "@heroicons/react/solid";
import FolderSelectionModal from "./FolderSelectionModal";
import { Dialog, Transition } from "@headlessui/react";
import CommandPalette from "./CommandPalette";
import moment, { duration } from "moment";
import InboxContent from "./InboxContent";
import ReactPlayer from "react-player";
import "react-h5-audio-player/lib/styles.css";
import "./player.css";
import { PencilAltIcon } from "@heroicons/react/solid";
import TimestampNoteModal from "./TimestampNoteModal";
import { Waveform } from "../../UI/WaveForm";
import parse from "html-react-parser";
import {
  DocumentTextIcon,
  TrashIcon,
  ExternalLinkIcon,
  ShareIcon,
  BookmarkIcon,
  FastForwardIcon,
  ClockIcon,
  BookOpenIcon,
  FlagIcon,
  RefreshIcon,
} from "@heroicons/react/solid";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import { useCopyToClipboard } from "usehooks-ts";
import ActionModalPleaseSubscribeToUse from "../../UI/ActionModalPleaseSubscribeToUse";
import { ExclamationIcon } from "@heroicons/react/solid";
import ExportModal from "./ExportModal";
import ShareEpisodeModal from "./ShareEpisodeModal";
import {
  ArchiveIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsHorizontalIcon,
  FolderDownloadIcon,
  PencilIcon,
  ReplyIcon,
  SearchIcon,
  UserAddIcon,
  LightningBoltIcon,
  ChatAltIcon,
  MenuAlt1Icon,
  GiftIcon,
  SearchCircleIcon,
  ArrowUpIcon,
  PlusIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import { BarLoader, BeatLoader } from "react-spinners";
import Img from "../../UI/Img";
import PodscribeActionModal from "./PodscribeActionModal";
import { remove_html_tags } from "../../common/utils";
import { Link } from "react-router-dom";
import AddInboxContentModal from "./add_inbox/AddInboxContentModal";
import JobStatusModal from "./JobStatusModal"; // Import the new component
import { FiRefreshCw } from "react-icons/fi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const InboxMessage = (props) => {
  const [processingInterval, setProcessingInterval] = useState(null);
  const [showEditor, setShowEditor] = useState(true);
  const [showTranscribe, setShowTranscribe] = useState(true);
  const [playAudio, setPlayAudio] = useState(false);
  const [toggleEpisodeDescription, setToggleEpisodeDescription] = useState(true);
  const [TranscribeInProgress, setTranscribeInProgress] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showPodscribeModal, setShowPodscribeModal] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const { auth } = useAuth();
  const [copiedText, copy] = useCopyToClipboard();
  const [shareUrl, setShareUrl] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
  const [isAddInboxContentModalOpen, setIsAddInboxContentModalOpen] = useState(false);
  const [isJobStatusModalOpen, setIsJobStatusModalOpen] = useState(false); // Add new state
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "p") {
        event.preventDefault();
        setIsCommandPaletteOpen(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isTimestampModalOpen, setIsTimestampModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);

  const [displayTime, setDisplayTime] = useState("");

  useEffect(() => {
    if (props.episode.is_processing) {
      let processingData = UIState.episodeState.processingTimes[props.episode.id];

      if (!processingData) {
        processingData = calculateProcessingTime(props.episode.duration);
        updateEpisodeState(props.episode.id, {
          processingTime: processingData,
        });
      }

      const interval = setInterval(() => {
        const remaining = getRemainingTime(processingData.startTime, processingData.estimatedSeconds);

        setDisplayTime(remaining.display);

        if (remaining.remainingSeconds <= 0) {
          clearInterval(interval);
          refreshLibraryEpisode(props.episode.feedId, props.episode.id);
        }
      }, 1000);

      setProcessingInterval(interval);

      return () => clearInterval(interval);
    } else {
      if (processingInterval) {
        clearInterval(processingInterval);
        setProcessingInterval(null);
      }
      if (!props.episode.is_processing) {
        updateEpisodeState(props.episode.id, { processingTime: null });
      }
    }
  }, [props.episode.id, props.episode.is_processing, props.episode.duration]);

  useEffect(() => {
    if (playerRef.current) {
      const savedPosition = UIState.episodeState.playerPositions[props.episode.id];
      if (savedPosition) {
        playerRef.current.seekTo(savedPosition);
      }
    }
  }, [props.episode.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current && playAudio) {
        const currentTime = playerRef.current.getCurrentTime();
        updateEpisodeState(props.episode.id, { playerPosition: currentTime });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [props.episode.id, playAudio]);

  useEffect(() => {
    if ("mediaSession" in navigator && props.episode) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: props.episode.title,
        artist: props.episode.podcast_title,
        album: props.episode.podcast_title,
        artwork: [
          {
            src: props.episode.feedImage,
            sizes: "512x512",
            type: "image/jpeg",
          },
        ],
      });

      // Add media session action handlers
      navigator.mediaSession.setActionHandler("play", () => setPlayAudio(true));
      navigator.mediaSession.setActionHandler("pause", () => setPlayAudio(false));
      navigator.mediaSession.setActionHandler("seekbackward", (details) => {
        const skipTime = details.seekOffset || 10;
        const currentTime = playerRef.current.getCurrentTime();
        const newTime = Math.max(currentTime - skipTime, 0);
        playerRef.current.seekTo(newTime);
        // Update position state after seeking
        navigator.mediaSession.setPositionState({
          duration: playerRef.current.getDuration(),
          playbackRate: 1,
          position: newTime,
        });
      });
      navigator.mediaSession.setActionHandler("seekforward", (details) => {
        const skipTime = details.seekOffset || 10;
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        const newTime = Math.min(currentTime + skipTime, duration);
        playerRef.current.seekTo(newTime);
        // Update position state after seeking
        navigator.mediaSession.setPositionState({
          duration: duration,
          playbackRate: 1,
          position: newTime,
        });
      });
      navigator.mediaSession.setActionHandler("seekto", (details) => {
        if (details.fastSeek && !("fastSeek" in playerRef.current)) {
          return; // fastSeek not supported
        }
        const newTime = details.seekTime;
        const duration = playerRef.current.getDuration();
        if (newTime >= 0 && newTime <= duration) {
          playerRef.current.seekTo(newTime);
          // Update position state after seeking
          navigator.mediaSession.setPositionState({
            duration: duration,
            playbackRate: 1,
            position: newTime,
          });
        }
      });
    }
  }, [props.episode, props.episode?.feedImage]);
  const [currentTranscriptText, setCurrentTranscriptText] = useState("");
  const [currentParagraphStart, setCurrentParagraphStart] = useState(0);
  const [currentParagraphEnd, setCurrentParagraphEnd] = useState(0);
  const { UIState, setUIState, updateEpisodeState } = useUIState();
  const {
    startEpisodePolling,
    LibraryData,
    addEpisodeToFolder,
    toggleStarEpisode,
    refreshLibraryEpisode,
    getUserJobs,
  } = useLibraryData();

  const handleAddEpisodeToFolder = useCallback(
    async (folderId, episodeId) => {
      try {
        await addEpisodeToFolder(folderId, episodeId);
        toast.success("Episode added to folder");
      } catch (error) {
        console.error("Error adding episode to folder:", error);
        toast.error("Failed to add episode to folder");
      }
    },
    [addEpisodeToFolder]
  );

  const handleEditorSave = async (content) => {
    try {
      await axiosPrivate.post("/users/notes", {
        data: content.data,
        episode_id: content.episode_id,
      });
      console.log("Note saved successfully!");
    } catch (err) {
      console.error("Error saving note:", err);
      toast.error("Failed to save note");
    }
  };

  const handleInboxSidebar = () => {
    setUIState({
      ...UIState,
      inboxSidebarOpen: !UIState.inboxSidebarOpen,
    });
  };

  // handle on close the podscribe modal window
  const HandleOnClosePodscribeModal = () => {
    setShowPodscribeModal(false);
  };
  // handle on close the podscribe modal window
  const ShowPodscribeModal = () => {
    setShowPodscribeModal(true);
  };

  // useRef for the Audio Player
  const playerRef = useRef(null);

  // Function to check if the URL is a YouTube link
  const isYouTubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // Memoize the result of isYouTubeUrl
  const isYouTube = useMemo(() => isYouTubeUrl(props.episode.enclosureUrl), [props.episode.enclosureUrl]);

  // control the player based on the clicked segment

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const description = showFullDescription ? props.episode.description : `${props.episode.description.slice(0, 100)}...`;

  useEffect(() => {
    // This effect will run on every render
    setPlayAudio(false);
  }, [props.selectedEpisodeState]); // Empty dependency array means this runs once on mount and on every rerender

  const SelectSegmentHandler = (time) => {
    playerRef.current.seekTo(time, "seconds");
    // start playing
    setPlayAudio(true);
  };

  // HandleTranscript button. Show transcribe set to True or False
  const HandleShowTranscript = () => {
    setShowTranscribe(!showTranscribe);
  };

  // HandleEditor button. Show Editor set to True or False
  const HandleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  // Handle Toggle Episode Description
  const HandleToggleEpisodeDescription = () => {
    setToggleEpisodeDescription(!toggleEpisodeDescription);
  };

  // Handle Transcribe button. Show transcribe set to True or False
  const HandleTranscribeEpisode = async () => {
    // Calculate minutes to be used
    const minutesToUse = Math.ceil(props.episode.duration / 60);

    // Set loading state to true to prevent multiple clicks
    setIsTranscribing(true);

    try {
      // send API request
      const response = await axiosPrivate.post(`/podcast/transcription/${props.episode.id}`);

      console.log(response);
      toast.success("Episode transcribing. Please wait a few minutes...");
      setTranscribeInProgress(true);

      // set the episode state to transcribe in progress
      props.episode.is_processing = true;

      // update the user minutes
      auth.user.podscribe_minutes = auth.user.podscribe_minutes - minutesToUse;
      if (auth.user.podscribe_minutes < 0) {
        auth.user.podscribe_topup_minutes += auth.user.podscribe_minutes;
        auth.user.podscribe_minutes = 0;
      }

      // close modal
      setShowPodscribeModal(false);
    } catch (error) {
      // Enhanced error handling
      const errorMessage = error?.response?.data?.detail || "Failed to start transcription. Please try again.";
      toast.error(errorMessage);
      console.error("Transcription error:", error);
    } finally {
      // Always reset loading state when done
      setIsTranscribing(false);
    }
  };

  // Handle Upvote of the episode using the /podcast/upvote/<episode_id> API

  const HandleUpvoteEpisode = () => {
    // send API request
    const response = axiosPrivate
      .post(`/podcast/upvote/${props.episode.id}`)
      .then((response) => {
        console.log(response);
        toast.success("Episode Like recorded. Thank you!");
      })
      .catch((error) => {
        // print error details if present
        error &&
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          toast.error(error.response.data.detail);
        console.log(error);
      });
  };

  const HandleShareEpisode = () => {
    const isSubscribed = auth.user.subscription_status === "active";
    let shareLinkUrl;

    if (isSubscribed) {
      axiosPrivate
        .post(`/podcast/episode/${props.episode.id}/auth-link`)
        .then((response) => {
          const shareLink = response.data.key;
          shareLinkUrl = `https://podscribe.io/content/podcasts/${props.episode.feedId}/episode/${props.episode.id}?auth=${shareLink}`;
          setShareUrl(shareLinkUrl);
          setIsShareModalOpen(true);
        })
        .catch((error) => {
          console.error("Error generating share link:", error);
          toast.error("Failed to generate share link. Please try again.");
        });
    } else {
      // Generate example data for non-subscribed users
      shareLinkUrl = `https://podscribe.io/content/podcasts/example-feed/episode/example-episode?auth=example-key`;
      setShareUrl(shareLinkUrl);
      setIsShareModalOpen(true);
    }
  };

  const handleCopyToClipboard = () => {
    copy(shareUrl);
    setIsShareModalOpen(false);
  };

  return (
    <section
      aria-labelledby="message-heading"
      className="min-w-0 flex-1 h-full flex flex-col lg:order-last lg:rounded-lg lg:mt-3 lg:mr-3 lg:ml-3 overflow-hidden">
      {/* Top section */}
      <div className="flex-shrink-0 bg-white border-b border-neutral-200 z-10">
        {/* Toolbar*/}

        <div className="flex-shrink-0 h-16 flex flex-col justify-center">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="py-3 flex justify-between">
              {/* Left buttons */}
              <div className="">
                <span className="relative z-0 inline-flex shadow-sm rounded-md border-neutral-100">
                  <button
                    type="button"
                    className="lg:hidden mr-2 relative inline-flex items-center px-2 py-2 rounded-md border border-neutral-200 
                    bg-white text-sm font-medium text-neutral-700 hover:bg-neutral-50 
                    transition-colors duration-150 ease-in-out
                    focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={() => handleInboxSidebar()}>
                    <span className="sr-only">Open Mobile sidebar</span>
                    <MenuAlt1Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <div className="inline-flex rounded-md shadow-sm space-x-0">
                    <button
                      type="button"
                      onClick={() => {
                        if (props.episode.is_processing) {
                          refreshLibraryEpisode(props.episode.feedId, props.episode.id);
                        } else if (!props.episode.is_summarized) {
                          ShowPodscribeModal();
                        }
                      }}
                      disabled={props.episode.is_summarized}
                      className={`relative inline-flex items-center sm:px-4 px-2 py-2 rounded-l-md border 
                        ${
                          props.episode.is_summarized
                            ? "border-primary-200 bg-primary-50 text-primary-800 cursor-not-allowed"
                            : props.episode.is_processing
                            ? "border-neutral-200 bg-white text-primary-600 hover:bg-primary-50"
                            : "border-neutral-200 bg-white text-secondary-600 hover:bg-secondary-50"
                        }
                        text-sm font-medium transition-colors duration-150 ease-in-out
                        focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2
                        ${props.episode.is_processing ? "focus:ring-primary-500" : "focus:ring-secondary-500"}`}>
                      {props.episode.is_summarized ? (
                        <CheckIcon className="h-5 w-5 text-support-green-500" aria-hidden="true" />
                      ) : props.episode.is_processing ? (
                        <FiRefreshCw className="animate-spin h-5 w-5 text-primary-600" aria-hidden="true" />
                      ) : (
                        <LightningBoltIcon className="h-5 w-5 text-secondary-600" aria-hidden="true" />
                      )}
                      <span className="hidden sm:block ml-2">
                        {props.episode.is_summarized
                          ? "Processed"
                          : props.episode.is_processing
                          ? "Processing"
                          : "Podscribe"}
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsJobStatusModalOpen(true)}
                      className="relative -ml-px inline-flex items-center sm:px-4 px-2 py-2 border
                        border-neutral-200 bg-white text-sm font-medium text-primary-700
                        hover:bg-primary-50 hover:text-primary-800
                        transition-colors duration-150 ease-in-out
                        focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      title="View job status">
                      <ClockIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="hidden sm:block ml-2">Jobs</span>
                    </button>
                    <button
                      onClick={() => setIsCommandPaletteOpen(true)}
                      className="relative -ml-px inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border
                        border-neutral-200 bg-white text-sm font-medium text-neutral-700
                        hover:bg-neutral-50 hover:text-primary-600
                        transition-colors duration-150 ease-in-out
                        focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                        active:bg-neutral-100 active:text-primary-700"
                      title="More actions">
                      <span className="sr-only">Open command palette</span>
                      <DotsHorizontalIcon
                        className="h-5 w-5 transform transition-transform duration-200 hover:scale-110"
                        aria-hidden="true"
                      />
                    </button>
                    <CommandPalette
                      isOpen={isCommandPaletteOpen}
                      onClose={() => setIsCommandPaletteOpen(false)}
                      onCommandSelect={(action) => {
                        switch (action) {
                          case "share":
                            HandleShareEpisode();
                            break;
                          case "export":
                            setIsExportModalOpen(true);
                            break;
                          case "star":
                          case "unstar":
                            toggleStarEpisode(props.episode.id);
                            break;
                          case "like":
                            HandleUpvoteEpisode();
                            break;
                          case "addToFolder":
                            setIsFolderModalOpen(true);
                            break;
                          case "download":
                            window.open(props.episode.enclosureUrl, "_blank");
                            break;
                          case "bookmark":
                            alert("Bookmark feature coming soon!");
                            break;
                          case "speed":
                            alert("Speed settings coming soon!");
                            break;
                          case "timer":
                            alert("Sleep timer coming soon!");
                            break;
                          case "chapters":
                            alert("Chapters feature coming soon!");
                            break;
                          case "report":
                            alert("Report issue coming soon!");
                            break;
                          case "remove":
                            props.RemoveEpisodeHandler(props.episode.feedId, props.episode.id);
                            break;
                        }
                      }}
                    />
                  </div>
                </span>
              </div>

              {/* Right buttons */}
              <nav aria-label="Pagination">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    onClick={props.handleOpenSearchModal}
                    className="relative inline-flex items-center sm:px-4 px-2 py-2 rounded-l-md border 
                    border-neutral-200 bg-white text-sm font-medium text-neutral-700 
                    hover:bg-neutral-50 hover:text-primary-600
                    transition-colors duration-150 ease-in-out
                    focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <span className="sr-only">Search</span>
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={() => setIsAddInboxContentModalOpen(true)}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 border
                      border-neutral-200 bg-secondary-50 text-sm font-medium text-secondary-700
                      hover:bg-secondary-100
                      transition-colors duration-150 ease-in-out
                      focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                    title="Add content to inbox">
                    <PlusIcon
                      className="h-5 w-5 transform transition-transform duration-200 hover:scale-110"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Add content to inbox</span>
                  </button>
                  <button
                    onClick={() => props.SelectEpisodeHandler(props.selectedEpisodeState.episode_id, "previous")}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 border
                      border-neutral-200 bg-white text-sm font-medium text-neutral-700
                      hover:bg-neutral-50 hover:text-primary-600
                      transition-colors duration-150 ease-in-out 
                      focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    title="Previous episode">
                    <span className="sr-only">Previous episode</span>
                    <ChevronUpIcon
                      className="h-5 w-5 transform transition-transform duration-200 hover:scale-110"
                      aria-hidden="true"
                    />
                  </button>
                  <button
                    onClick={() => props.SelectEpisodeHandler(props.selectedEpisodeState.episode_id, "next")}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border
                      border-neutral-200 bg-white text-sm font-medium text-neutral-700
                      hover:bg-neutral-50 hover:text-primary-600
                      transition-colors duration-150 ease-in-out
                      focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    title="Next episode">
                    <span className="sr-only">Next episode</span>
                    <ChevronDownIcon
                      className="h-5 w-5 transform transition-transform duration-200 hover:scale-110"
                      aria-hidden="true"
                    />
                  </button>
                </span>
              </nav>
            </div>
          </div>
        </div>
        {/* Message header */}
      </div>
      {/* Message Content */}
      <div
        id="message-content-scroll-target"
        className="bg-white shadow h-full overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300">
        <div className="flex flex-col px-4 py-4 sm:px-6 lg:px-8 text-white">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <Img
                src={props.episode.feedImage}
                className="h-16 w-16 text-neutral-400 rounded-lg object-cover shadow-sm"
                aria-hidden="true"
              />
            </div>
            <div className="min-w-0 flex-1">
              <div className="flex items-center justify-between">
                <h1 className="text-lg font-medium text-primary-700 truncate">
                  <a
                    href={props.episode.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline inline-flex items-center">
                    <span className="mr-2">{props.episode.title}</span>
                    <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  </a>
                </h1>
              </div>
              <p className="text-sm font-medium text-secondary-700">{props.episode.podcast_title}</p>
              {toggleEpisodeDescription && (
                <div className="mt-2 text-sm text-gray-600">
                  <p className="prose prose-sm">
                    {remove_html_tags(description)}
                    {props.episode.description.length > 100 && (
                      <button className="ml-1 text-primary-500 hover:underline" onClick={toggleDescription}>
                        {showFullDescription ? "Less" : "More"}
                      </button>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Player */}
          <div className="mt-4">
            <div
              className={`relative ${
                isYouTube || props.episode.content_type === "youtube" ? "h-[100px]" : "h-[40px]"
              } w-full rounded-lg overflow-hidden shadow-sm bg-gray-50`}>
              <ReactPlayer
                key={props.episode.id}
                ref={playerRef}
                url={props.episode.enclosureUrl}
                playing={playAudio}
                controls={true}
                width="100%"
                height={isYouTube || props.episode.content_type === "youtube" ? "100px" : "40px"}
                className="absolute"
                onPlay={() => {
                  setPlayAudio(true);
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.playbackState = "playing";
                  }
                }}
                onPause={() => {
                  setPlayAudio(false);
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.playbackState = "paused";
                  }
                }}
                onEnded={() => {
                  setPlayAudio(false);
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.playbackState = "none";
                  }
                }}
                onProgress={({ played, playedSeconds }) => {
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.setPositionState({
                      duration: playerRef.current?.getDuration() || 0,
                      playbackRate: 1,
                      position: playedSeconds,
                    });
                  }
                }}
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 0,
                      controls: 1,
                      modestbranding: 1,
                    },
                  },
                  file: {
                    forceAudio: !(isYouTube || props.episode.content_type === "youtube"),
                    attributes: {
                      style: { width: "100%", height: "40px" },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8 pb-6">
          {showTranscribe ? (
            <InboxContent
              data={props.data}
              SelectSegmentHandler={SelectSegmentHandler}
              onEditorChange={props.onEditorChange}
              editorData={props.EditorData}
              showEditor={showEditor}
              episode={props.episode}
              selectedEpisodeState={props.selectedEpisodeState}
              onNoteEditorChange={handleEditorSave}
              HandleTranscribeEpisode={ShowPodscribeModal}
            />
          ) : null}
        </div>
      </div>
      <PodscribeActionModal
        show={showPodscribeModal}
        HandleOnClose={HandleOnClosePodscribeModal}
        HandleOnAction={HandleTranscribeEpisode}
        ActionButtonText="Podscribe"
        CloseButtonText="Cancel"
        title="Process episode"
        availableMinutes={auth.user.podscribe_minutes + auth.user.podscribe_topup_minutes}
        requiredMinutes={Math.ceil(props.episode.duration / 60)}
        isLoading={isTranscribing}
        BodyText={
          <div className="space-y-4">
            <h4 className="font-semibold text-lg">AI Processing Confirmation</h4>
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                You're about to AI process this episode. Here's what you need to know:
              </p>
              <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                <li>
                  Estimated cost:{" "}
                  <span className="font-semibold text-gray-800">{Math.ceil(props.episode.duration / 60)} minutes</span>
                </li>
                <li>
                  Your available balance:{" "}
                  <span className="font-semibold text-gray-800">
                    {auth.user.podscribe_minutes + auth.user.podscribe_topup_minutes} minutes
                  </span>
                  {auth.user.subscription_status === "active" ? " (subscription + top-up)" : ""}
                </li>
                <li>Actual processing time may vary based on episode length and system workload</li>
                <li>Typical completion time: A few minutes</li>
              </ul>
            </div>
            {auth.user.subscription_status !== "active" && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      As a free user, you have a limit of 100 minutes. Once exhausted, you'll need to subscribe to
                      continue processing new content.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <p className="font-medium">Are you sure you want to proceed with processing this episode?</p>
          </div>
        }
      />
      {/* Timestamp note button - only visible when episode is summarized */}
      {props.episode.is_summarized && (
        <div className="fixed bottom-8 right-8 z-30">
          <div className="relative group">
            <div className="absolute -inset-2 bg-secondary-500/10 rounded-full blur-lg opacity-0 group-hover:opacity-100 transition-all duration-300"></div>
            <button
              type="button"
              onClick={() => {
                const currentTime = Math.floor(playerRef.current.getCurrentTime());
                const relevantText = props.selectedEpisodeState.episode.transcription?.paragraphs.paragraphs.find(
                  (p) => currentTime >= p.start && currentTime <= p.end
                );
                setCurrentTimestamp(currentTime);
                setCurrentTranscriptText(relevantText || null);
                setIsTimestampModalOpen(true);
                if (relevantText) {
                  setCurrentParagraphStart(relevantText.start);
                  setCurrentParagraphEnd(relevantText.end);
                }
              }}
              className="relative inline-flex items-center justify-center p-3 rounded-full
                        bg-white text-secondary-600 shadow-lg
                        hover:bg-secondary-50 hover:text-secondary-700
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500
                        transform transition-all duration-200 
                        hover:scale-105 active:scale-95
                        hover:shadow-xl hover:shadow-secondary-500/20"
              title="Add Highlight"
              aria-label="Add Highlight">
              <PencilAltIcon className="w-6 h-6" />
              <span className="sr-only">Add Highlight</span>
            </button>
            <div className="absolute -bottom-1 -right-1 w-3 h-3">
              <span className="flex h-2 w-2 relative">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-secondary-500"></span>
              </span>
            </div>
          </div>
        </div>
      )}
      <TimestampNoteModal
        show={isTimestampModalOpen}
        onClose={() => setIsTimestampModalOpen(false)}
        timestamp={currentTimestamp}
        transcriptText={currentTranscriptText}
        paragraphStart={currentParagraphStart}
        paragraphEnd={currentParagraphEnd}
        onSave={({ timestamp, note, transcriptText }) => {
          const timestampStr = `${Math.floor(timestamp / 60)}:${(timestamp % 60).toString().padStart(2, "0")}`;
          const transcriptTextContent =
            transcriptText?.sentences?.map((s) => s.text).join(" ") || "No transcript available";
          const noteText = `\n\n# Audio Highlight [${timestampStr}]\n\n >${transcriptTextContent}\n\n## My Notes\n${note}\n\n---\n`;

          // Append to existing notes
          const updatedNote = (props.episode.note || "") + noteText;
          props.selectedEpisodeState.episode.note = updatedNote;
          handleEditorSave({
            episode_id: props.episode.id,
            data: updatedNote,
          });

          setIsTimestampModalOpen(false);
          setPlayAudio(true);
        }}
      />
      <ShareEpisodeModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        shareUrl={shareUrl}
        onCopyToClipboard={handleCopyToClipboard}
        isSubscribed={auth.user.subscription_status === "active"}
      />
      <ActionModalPleaseSubscribeToUse
        show={showSubscribeModal}
        HandleOnClose={() => setShowSubscribeModal(false)}
        title="Subscribe to Share"
        BodyText="To share episodes, you need an active subscription. Subscribe now to unlock this feature and many more!"
        CloseButtonText="Close"
      />
      <ExportModal show={isExportModalOpen} onClose={() => setIsExportModalOpen(false)} episode={props.episode} />
      <FolderSelectionModal
        show={isFolderModalOpen}
        onClose={() => setIsFolderModalOpen(false)}
        folders={LibraryData.folders}
        episodeId={props.episode?.id}
      />
      <AddInboxContentModal show={isAddInboxContentModalOpen} onClose={() => setIsAddInboxContentModalOpen(false)} />
      <JobStatusModal
        isOpen={isJobStatusModalOpen}
        onClose={() => setIsJobStatusModalOpen(false)}
        getUserJobs={getUserJobs}
      />
    </section>
  );
};

export default InboxMessage;
