import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { Button } from './Button';
import { Container } from './Container';
import { PlayIcon } from '@heroicons/react/solid';
import { CursorClickIcon } from '@heroicons/react/outline';
// Removing the local import
// import productvideo from '../../assets/videos/productvideo.mp4';
import productThumbnail from '../../assets/screenshots/v2/video.png'; // Add your thumbnail here
import './VideoSection.css';

// Add the remote URL as a constant
const PRODUCT_VIDEO_URL = 'https://podscribepub.s3.us-east-1.amazonaws.com/assets/productvideo.mp4';

export function VideoSection() {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [cursorPos, setCursorPos] = useState({ x: 50, y: 50 });
  const animationRef = useRef(null);

  // Animated cursor effect
  useEffect(() => {
    if (!showVideo) {
      // Create an interesting motion pattern that highlights features
      const keypoints = [
        { x: 30, y: 40 }, // Point 1 
        { x: 70, y: 30 }, // Point 2
        { x: 75, y: 60 }, // Point 3
        { x: 40, y: 70 }, // Point 4
        { x: 50, y: 50 }  // Center
      ];
      
      let currentPoint = 0;
      let progress = 0;
      const speed = 0.02;
      
      const animateCursor = () => {
        const current = keypoints[currentPoint];
        const next = keypoints[(currentPoint + 1) % keypoints.length];
        
        // Interpolate between points
        const x = current.x + (next.x - current.x) * progress;
        const y = current.y + (next.y - current.y) * progress;
        
        setCursorPos({ x, y });
        
        progress += speed;
        if (progress >= 1) {
          progress = 0;
          currentPoint = (currentPoint + 1) % keypoints.length;
        }
        
        animationRef.current = requestAnimationFrame(animateCursor);
      };
      
      animationRef.current = requestAnimationFrame(animateCursor);
      
      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
      };
    }
  }, [showVideo]);

  // Video.js initialization
  useEffect(() => {
    if (showVideo && !playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
          src: PRODUCT_VIDEO_URL,
          type: 'video/mp4'
        }],
        playbackRates: [0.5, 1, 1.5, 2]
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [showVideo]);

  const handlePlayClick = () => {
    setShowVideo(true);
  };

  return (
    <Container className="py-16">
      <h2 className="mx-auto max-w-4xl font-display text-3xl font-medium tracking-tight text-primary-900 text-center mb-10">
        Go Beyond Listening: <span className="text-secondary-600">Search & Ask</span>{" "}
        Audio with PodScribe.IO
      </h2>

      <div className="relative max-w-4xl mx-auto my-10">
        {/* Shadow/glow container */}
        <div className="absolute -inset-4 bg-gradient-to-r from-primary-400/30 to-secondary-400/30 rounded-[28px] blur-3xl opacity-70"></div>

        {showVideo ? (
          <div className="relative video-container w-full aspect-video overflow-hidden rounded-xl ring-1 ring-white/10 bg-neutral-900/80"
               data-vjs-player>
            <video ref={videoRef} className="video-js vjs-big-play-centered w-full h-full" />
          </div>
        ) : (
          <div className="relative w-full aspect-video overflow-hidden rounded-xl ring-1 ring-white/10 cursor-pointer group"
               onClick={handlePlayClick}>
            {/* Thumbnail */}
            <img src={productThumbnail} 
                 alt="Video preview" 
                 className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105" />
            
            {/* Play button overlay */}
            <div className="absolute inset-0 flex items-center justify-center bg-black/25 group-hover:bg-black/40 transition-all duration-300">
              <div className="bg-secondary-600 p-5 rounded-full shadow-xl transform transition-all duration-500 
                              group-hover:scale-110 group-hover:bg-secondary-500 pulse-animation">
                <PlayIcon className="h-12 w-12 text-white" />
              </div>
            </div>
            
            {/* Animated cursor */}
            <div className="absolute pointer-events-none transition-all duration-300"
                 style={{ left: `${cursorPos.x}%`, top: `${cursorPos.y}%`, transform: 'translate(-50%, -50%)' }}>
              <CursorClickIcon className="h-8 w-8 text-black drop-shadow-lg animate-bounce" />
              <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 bg-black/80 text-white text-sm 
                              px-3 py-1 rounded-full whitespace-nowrap">
                Click to play demo
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-10 text-center">
        <Button href="/register" className="mx-auto px-8 py-4 text-lg">
          Try It Free Today
        </Button>
        <p className="mt-3 text-sm text-primary-600/80">No credit card required • 100 minutes included</p>
      </div>
    </Container>
  );
}