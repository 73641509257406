import { CallToAction } from "./Landing/CallToAction";
import { Faqs } from "./Landing/Faqs";
import { Footer } from "./Landing/Footer";
import { Header } from "./Landing/Header";
import { Hero } from "./Landing/Hero";
import { VideoSection } from "./Landing/VideoSection";
import { Pricing } from "./Landing/Pricing";
import { PrimaryFeatures } from "./Landing/PrimaryFeatures";
import { SecondaryFeatures } from "./Landing/SecondaryFeatures";
import { Testimonials } from "./Landing/Testimonials";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { BannerWithLinks } from "../UI/Banner";
import SEO from "./SEO";
import CookieConsent from "./CookieConsent";

export default function Home() {
  return (
    <>
      <SEO />
      <div id="banner"></div>
      {/* <BannerWithLinks link="/register" strongText="🎉 Happy New Year 2025!">
        Start your year right with 15% OFF all yearly plans! Use code: NY2025 →
      </BannerWithLinks> */}
      <Header />
      <main>
        <Hero />
        <VideoSection />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <Pricing />
        <CallToAction />
      </main>
      <Footer />
      <CookieConsent />
    </>
  );
}
