import { createContext, useState, useEffect, useRef, useCallback } from "react";
import useAuth from "../hooks/useAuth";
import { axiosPrivate } from "../common/axiosPrivate";
import { toast } from "react-hot-toast";

const BACKGROUND_PAGE_SIZE = 50; // Larger page size for background loading

const LibraryDataContext = createContext({
  podcasts: [],
  episodes: [],
  notes: [],
  loading: false,
  error: null,
  episodeCount: 0,
});

export const LibraryDataProvider = ({ children }) => {
  const { auth } = useAuth();
  const [LibraryData, setLibraryData] = useState({
    podcasts: [],
    episodes: [],
    notes: [],
    folders: [], // Each folder should have {id, name, episodes: []}
    loading: true,
    error: null,
    episodeCount: 0,
    hasMoreEpisodes: true, // Track if more episodes are available
    currentPage: 1, // Track current page (FastAPI pagination starts at 1)
    totalPages: 1, // Total number of pages
    isFetchingMore: false, // Track if we're currently fetching more episodes
    isBackgroundLoading: false,
  });

  // Use refs to track loading state
  const allEpisodesLoaded = useRef(false);
  const initialLoadComplete = useRef(false);
  const PAGE_SIZE = 10;

  const refreshLibrary = async (resetPagination = true) => {
    setLibraryData((prevData) => ({
      ...prevData,
      loading: true,
      currentPage: resetPagination ? 1 : prevData.currentPage,
      hasMoreEpisodes: resetPagination ? true : prevData.hasMoreEpisodes,
    }));

    try {
      const [response, foldersResponse] = await Promise.all([
        axiosPrivate.get(
          `/library/?page=${resetPagination ? 1 : LibraryData.currentPage}&size=${PAGE_SIZE}`,
        ),
        axiosPrivate.get("/folders/"),
      ]);

      const { items, total, page, pages } = response.data;

      setLibraryData((prevData) => {
        const episodes = resetPagination
          ? items
          : [...prevData.episodes, ...items];

        const hasMoreEpisodes = page < pages;

        return {
          episodes: episodes,
          folders: foldersResponse.data.map((folder) => ({
            ...folder,
            episodes: folder.episodes || [],
          })),
          loading: false,
          error: null,
          episodeCount: total,
          hasMoreEpisodes: hasMoreEpisodes,
          currentPage: page,
          totalPages: pages,
          isFetchingMore: false,
        };
      });

      initialLoadComplete.current = true;

      if (resetPagination && page < pages && !allEpisodesLoaded.current) {
        loadRemainingEpisodesInBackground();
      }
    } catch (error) {
      console.log(error);
      setLibraryData((prevData) => ({
        ...prevData,
        loading: false,
        isFetchingMore: false,
        error: error,
      }));
    }
  };

  const fetchEpisodeCount = async () => {
    try {
      const response = await axiosPrivate.get("/library/count");
      setLibraryData((prevData) => ({
        ...prevData,
        episodeCount: response.data.count,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const createFolder = async (folderData) => {
    try {
      const response = await axiosPrivate.post("/folders/", folderData);
      const newFolder = {
        ...response.data,
        episodes: [], // Initialize empty episodes array
      };
      setLibraryData((prevData) => ({
        ...prevData,
        folders: [...(prevData.folders || []), newFolder],
      }));
      return newFolder;
    } catch (error) {
      console.error("Error creating folder:", error);
      throw error;
    }
  };

  const updateFolder = async (folderId, folderData) => {
    try {
      const response = await axiosPrivate.put(
        `/folders/${folderId}`,
        folderData,
      );
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.map((folder) =>
          folder.id === folderId
            ? { ...response.data, episodes: folder.episodes }
            : folder,
        ),
      }));
      return response.data;
    } catch (error) {
      console.error("Error updating folder:", error);
      throw error;
    }
  };

  const deleteFolder = async (folderId) => {
    try {
      await axiosPrivate.delete(`/folders/${folderId}`);
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.filter((folder) => folder.id !== folderId),
      }));
    } catch (error) {
      console.error("Error deleting folder:", error);
      throw error;
    }
  };

  const addEpisodeToFolder = async (folderId, episodeId) => {
    try {
      const response = await axiosPrivate.post(
        `/folders/${folderId}/episodes/${episodeId}`,
      );
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.map((folder) => {
          if (folder.id === folderId) {
            return {
              ...folder,
              episodes: [...folder.episodes, episodeId],
            };
          }
          return folder;
        }),
      }));
      return response.data;
    } catch (error) {
      console.error("Error adding episode to folder:", error);
      throw error;
    }
  };

  const removeEpisodeFromFolder = async (folderId, episodeId) => {
    try {
      const response = await axiosPrivate.delete(
        `/folders/${folderId}/episodes/${episodeId}`,
      );
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.map((folder) => {
          if (folder.id === folderId) {
            return {
              ...folder,
              episodes: folder.episodes.filter((id) => id !== episodeId),
            };
          }
          return folder;
        }),
      }));
      return response.data;
    } catch (error) {
      console.error("Error removing episode from folder:", error);
      throw error;
    }
  };
  const loadRemainingEpisodesInBackground = useCallback(async () => {
    if (allEpisodesLoaded.current) return;

    try {
      // Start from page 1 with larger page size
      let currentPage = 1;
      let hasMore = true;

      console.log("Starting background loading with larger page size");
      setLibraryData((prev) => ({ ...prev, isBackgroundLoading: true }));

      while (hasMore) {
        console.log(
          `Loading page ${currentPage} with size ${BACKGROUND_PAGE_SIZE}...`,
        );

        const response = await axiosPrivate.get(
          `/library/?page=${currentPage}&size=${BACKGROUND_PAGE_SIZE}`,
        );

        // Destructure the standard FastAPI pagination response
        const { items, total, page, size, pages } = response.data;

        console.log(
          `Received page ${page}/${pages}, ${items.length} items, total: ${total}`,
        );

        if (items.length === 0) {
          console.log("No items received, stopping background load");
          hasMore = false;
          allEpisodesLoaded.current = true;
          break;
        }

        setLibraryData((prevData) => {
          // Create a map of existing episodes to avoid duplicates
          const existingEpisodes = new Map(
            prevData.episodes.map((episode) => [episode.id, episode]),
          );

          // Add new episodes, avoiding duplicates
          items.forEach((episode) => {
            if (!existingEpisodes.has(episode.id)) {
              existingEpisodes.set(episode.id, episode);
            }
          });

          // Convert map back to array
          const updatedEpisodes = Array.from(existingEpisodes.values());

          console.log(
            `Updated episodes count: ${updatedEpisodes.length}/${total}`,
          );

          return {
            ...prevData,
            episodes: updatedEpisodes,
            episodeCount: total,
            currentPage: page,
            hasMoreEpisodes: page < pages,
            totalPages: pages,
          };
        });

        // Check if we have more pages to load
        hasMore = page < pages;
        if (!hasMore) {
          console.log("Reached last page, background loading complete");
          allEpisodesLoaded.current = true;
        } else {
          currentPage++;
          // Add a small delay to prevent overwhelming the server
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }
    } catch (error) {
      console.error("Background loading error:", error);
      // Don't set allEpisodesLoaded here so it can retry later if needed
    } finally {
      setLibraryData((prev) => ({ ...prev, isBackgroundLoading: false }));
    }
  }, []);

  // fetch the data from the backend
  useEffect(() => {
    if (!auth.token) {
      return;
    }

    const fetchLibraryData = async () => {
      setLibraryData((prevData) => ({ ...prevData, loading: true }));
      try {
        const [response, foldersResponse] = await Promise.all([
          axiosPrivate.get(`/library/?page=1&size=${PAGE_SIZE}`),
          axiosPrivate.get("/folders/"),
        ]);

        const { items, total, page, pages } = response.data;
        const hasMore = total > items.length;

        console.log("Initial Library Data:", {
          items: items.length,
          total,
          page,
          pages,
          hasMore,
        });

        setLibraryData((prevState) => {
          const newState = {
            episodes: items,
            folders: foldersResponse.data.map((folder) => ({
              ...folder,
              episodes: folder.episodes || [],
            })),
            loading: false,
            error: null,
            episodeCount: total,
            hasMoreEpisodes: hasMore,
            currentPage: page,
            totalPages: pages,
            isFetchingMore: false,
          };

          // Schedule background loading after state update
          if (hasMore && !allEpisodesLoaded.current) {
            console.log("Scheduling background loading...");
            setTimeout(() => {
              console.log("Starting background loading...");
              loadRemainingEpisodesInBackground();
            }, 1000);
          } else {
            console.log("No background loading needed");
            allEpisodesLoaded.current = true;
          }

          return newState;
        });

        initialLoadComplete.current = true;
      } catch (error) {
        console.error("Error fetching library data:", error);
        setLibraryData((prevData) => ({
          ...prevData,
          loading: false,
          error: error,
        }));
      }
    };

    // Reset loading states when auth changes
    allEpisodesLoaded.current = false;
    initialLoadComplete.current = false;

    fetchLibraryData();
  }, [auth.token]);

  const refreshLibraryEpisode = async (feedId, episodeId) => {
    try {
      const response = await axiosPrivate.get(
        `/library/podcast/${feedId}/${episodeId}`,
      );
      const updatedEpisode = response.data;
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.map((episode) =>
          episode.id === episodeId ? updatedEpisode : episode,
        ),
        loading: false,
        error: null,
      }));
      return updatedEpisode;
    } catch (error) {
      console.log(error);
      setLibraryData((prevData) => ({ ...prevData, loading: false }));
      return null;
    }
  };

  const addLibraryEpisode = async (feedId, episodeId) => {
    try {
      console.log("Adding episode to library: ", feedId, episodeId);
      const response = await axiosPrivate.post(
        `/library/${feedId}/${episodeId}`,
      );
      console.log(response.data);
      const newEpisode = {
        ...response.data,
        isNew: true,
      };
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: [...prevData.episodes, newEpisode],
      }));
    } catch (error) {
      // if the error is because the episode is already in the library, just refresh the episode
      if (error.response && error.response.status === 409) {
        refreshLibraryEpisode(feedId, episodeId);
        toast.success("Episode already in library");
        return;
      }

      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  // add removeLibraryEpisode function
  const toggleStarEpisode = async (episodeId) => {
    try {
      const episode = LibraryData.episodes.find((ep) => ep.id === episodeId);
      const isCurrentlyStarred = episode?.is_starred || false;

      if (isCurrentlyStarred) {
        await axiosPrivate.delete(`/folders/star/${episodeId}`);
      } else {
        await axiosPrivate.post(`/folders/star/${episodeId}`);
      }

      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.map((episode) =>
          episode.id === episodeId
            ? { ...episode, is_starred: !isCurrentlyStarred }
            : episode,
        ),
      }));

      toast.success(
        isCurrentlyStarred ? "Episode unstarred" : "Episode starred",
      );
    } catch (error) {
      console.error("Error toggling star status:", error);
      toast.error("Failed to update star status");
    }
  };

  const removeLibraryEpisode = async (feedId, episodeId) => {
    try {
      await axiosPrivate.delete(`/library/${feedId}/${episodeId}`);
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.filter(
          (episode) => episode.id !== episodeId,
        ),
        // Also remove the episode from all folders
        folders: prevData.folders.map((folder) => ({
          ...folder,
          episodes: folder.episodes.filter((id) => id !== episodeId),
        })),
      }));
      toast.success("Episode removed from library");
    } catch (error) {
      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  const removeMultipleEpisodes = async (episodeIds) => {
    try {
      console.log("Episode IDs to remove:", episodeIds);

      // Convert episodeIds to strings for consistent comparison
      const stringEpisodeIds = episodeIds.map((id) => String(id));

      // Get the episode objects for the IDs - match by string comparison
      const episodesToRemove = LibraryData.episodes.filter((ep) =>
        stringEpisodeIds.includes(String(ep.id)),
      );

      console.log("Found episodes to remove:", episodesToRemove.length);

      if (episodesToRemove.length === 0) {
        console.error("No matching episodes found to remove");
        throw new Error("No matching episodes found");
      }

      // Build batch requests for each episode deletion
      const batchRequests = episodesToRemove.map((episode) => ({
        id: `delete-episode-${episode.id}`,
        method: "DELETE",
        url: `/library/${episode.feedId}/${episode.id}`,
      }));

      // Execute batch request
      const result = await executeBatch(batchRequests);

      if (!result.success) {
        throw new Error("Batch deletion failed");
      }

      // Update the state to remove the episodes
      setLibraryData((prevData) => {
        const updatedEpisodes = prevData.episodes.filter(
          (episode) => !stringEpisodeIds.includes(String(episode.id)),
        );

        // Also update folders
        const updatedFolders = prevData.folders.map((folder) => ({
          ...folder,
          episodes: folder.episodes.filter(
            (id) => !stringEpisodeIds.includes(String(id)),
          ),
        }));

        return {
          ...prevData,
          episodes: updatedEpisodes,
          folders: updatedFolders,
        };
      });

      return { success: true };
    } catch (error) {
      console.error("Error removing multiple episodes:", error);
      throw error;
    }
  };

  const executeBatch = async (requests) => {
    try {
      // Split requests into batches of 20 to respect backend limit
      const BATCH_SIZE_LIMIT = 20;
      const batches = [];
      
      for (let i = 0; i < requests.length; i += BATCH_SIZE_LIMIT) {
        batches.push(requests.slice(i, i + BATCH_SIZE_LIMIT));
      }
      
      console.log(`Split ${requests.length} requests into ${batches.length} batches`);
      
      // Process each batch and collect responses
      let allResponses = [];
      
      for (let i = 0; i < batches.length; i++) {
        const batch = batches[i];
        
        // Ensure each request has a unique ID
        const batchRequests = batch.map((req, index) => ({
          id: req.id || `batch-${i}-${index}`,
          method: req.method,
          url: `/api${req.url}`,
          body: req.body,
          headers: req.headers,
        }));
        
        console.log(`Processing batch ${i+1}/${batches.length} with ${batchRequests.length} requests`);
        
        // Call the batch endpoint
        const response = await axiosPrivate.post("/batch/", {
          requests: batchRequests,
        });
        
        // Collect responses
        allResponses = [...allResponses, ...response.data.responses];
      }
      
      return {
        success: true,
        responses: allResponses,
      };
    } catch (error) {
      console.error("Batch request failed:", error);
      return {
        success: false,
        error: error,
        responses: [],
      };
    }
  };

  const addMultipleEpisodesToFolder = async (folderId, episodeIds) => {
    if (!episodeIds || episodeIds.length === 0) {
      return { success: false, message: "No episodes selected" };
    }

    try {
      // Convert all IDs to strings for consistency
      const stringEpisodeIds = episodeIds.map((id) => String(id));

      // Track which episodes are already in the folder to avoid duplicates
      const folder = LibraryData.folders.find((f) => f.id === folderId);
      const existingEpisodes = new Set(
        folder?.episodes?.map((id) => String(id)) || [],
      );

      // Filter out episodes that are already in the folder
      const episodesToAdd = stringEpisodeIds.filter(
        (id) => !existingEpisodes.has(id),
      );

      if (episodesToAdd.length === 0) {
        return {
          success: true,
          added: 0,
          skipped: stringEpisodeIds.length,
          message: "All selected episodes are already in this folder",
        };
      }

      console.log("Attempting to add episodes to folder:", episodesToAdd);

      // Build batch requests for each episode addition
      const batchRequests = episodesToAdd.map((episodeId) => ({
        id: `add-to-folder-${episodeId}`,
        method: "POST",
        url: `/folders/${folderId}/episodes/${episodeId}`,
      }));

      // Execute batch request
      const result = await executeBatch(batchRequests);

      if (!result.success) {
        throw new Error("Batch operation failed");
      }

      // Process results to determine which operations succeeded
      const successfulEpisodeIds = [];
      let errorCount = 0;

      result.responses.forEach((response, index) => {
        if (response.status >= 200 && response.status < 300) {
          successfulEpisodeIds.push(episodesToAdd[index]);
        } else {
          errorCount++;
          console.error(
            `Error adding episode ${episodesToAdd[index]} to folder:`,
            response,
          );
        }
      });

      console.log("Successfully added episodes:", successfulEpisodeIds);

      // Force refresh folders to ensure state is updated properly
      if (successfulEpisodeIds.length > 0) {
        try {
          // Option 1: Full refresh of folders data
          const foldersResponse = await axiosPrivate.get("/folders/");

          setLibraryData((prevData) => ({
            ...prevData,
            folders: foldersResponse.data.map((folder) => ({
              ...folder,
              episodes: folder.episodes || [],
            })),
          }));

          // Log success for debugging
          console.log("Folders state refreshed after batch operation");
        } catch (folderError) {
          console.error(
            "Error refreshing folders after batch operation:",
            folderError,
          );

          // Fallback to local state update if API call fails
          setLibraryData((prevData) => {
            const updatedFolders = prevData.folders.map((folder) => {
              if (folder.id === folderId) {
                // Make sure we're using the correct ID format
                const currentEpisodes = folder.episodes || [];
                return {
                  ...folder,
                  episodes: [
                    ...new Set([...currentEpisodes, ...successfulEpisodeIds]),
                  ],
                };
              }
              return folder;
            });

            return {
              ...prevData,
              folders: updatedFolders,
            };
          });
        }
      }

      return {
        success: successfulEpisodeIds.length > 0,
        added: successfulEpisodeIds.length,
        failed: errorCount,
        skipped: stringEpisodeIds.length - episodesToAdd.length,
        message: `${successfulEpisodeIds.length} of ${episodesToAdd.length} episodes added to folder`,
      };
    } catch (error) {
      console.error("Error in batch add to folder operation:", error);
      throw error;
    }
  };

  const loadMoreEpisodes = async () => {
    if (LibraryData.isFetchingMore || !LibraryData.hasMoreEpisodes) {
      return;
    }

    setLibraryData((prevData) => ({
      ...prevData,
      isFetchingMore: true,
    }));

    try {
      const nextPage = LibraryData.currentPage + 1;
      const response = await axiosPrivate.get(
        `/library/?page=${nextPage}&size=${PAGE_SIZE}`,
      );
      const { items, total, page, pages } = response.data;

      setLibraryData((prevData) => {
        const updatedEpisodes = [...prevData.episodes, ...items];
        const hasMore = page < pages;

        return {
          ...prevData,
          episodes: updatedEpisodes,
          currentPage: page,
          hasMoreEpisodes: hasMore,
          totalPages: pages,
          isFetchingMore: false,
        };
      });

      if (items.length === 0 || page >= pages) {
        allEpisodesLoaded.current = true;
      }
    } catch (error) {
      console.log(error);
      setLibraryData((prevData) => ({
        ...prevData,
        isFetchingMore: false,
        error: error,
      }));
    }
  };

  const getEpisodeJobs = async (episodeId, jobType = null, status = null) => {
    try {
      // Build query parameters
      const params = new URLSearchParams();
      if (jobType) params.append("job_type", jobType);
      if (status) params.append("status", status);

      // Make API request to get jobs for this episode
      const response = await axiosPrivate.get(
        `/jobs/episode/${episodeId}${params.toString() ? `?${params.toString()}` : ""}`,
      );

      return {
        success: true,
        jobs: response.data.jobs,
        count: response.data.count,
      };
    } catch (error) {
      console.error("Error fetching episode jobs:", error);
      return {
        success: false,
        error: error,
        jobs: [],
        count: 0,
      };
    }
  };

  const getUserJobs = async (
    status = null,
    jobType = null,
    limit = 100,
    skip = 0,
  ) => {
    try {
      // Build query parameters
      const params = new URLSearchParams();
      if (status) params.append("status", status);
      if (jobType) params.append("job_type", jobType);
      params.append("limit", limit.toString());
      params.append("skip", skip.toString());

      // Make API request to get jobs for the current user
      const response = await axiosPrivate.get(
        `/jobs/jobs/user?${params.toString()}`,
      );

      return {
        success: true,
        jobs: response.data.jobs,
        count: response.data.count,
      };
    } catch (error) {
      console.error("Error fetching user jobs:", error);
      return {
        success: false,
        error: error,
        jobs: [],
        count: 0,
      };
    }
  };

  // New function to clean user jobs
  const cleanUserJobs = async (jobIds) => {
    try {
      // Make API request to clean jobs
      const response = await axiosPrivate.post("/jobs/clean", {
        jobIds: jobIds,
      });

      return {
        success: true,
        cleanedCount: response.data.cleanedCount,
      };
    } catch (error) {
      console.error("Error cleaning jobs:", error);
      return {
        success: false,
        error: error.response?.data?.detail || error.message || "Unknown error",
        cleanedCount: 0,
      };
    }
  };

  const batchExportEpisodes = async (episodeIds) => {
    try {
      const response = await axiosPrivate.post(
        "repository/batch-export/",
        { episode_ids: episodeIds },
        { responseType: "blob" }
      );
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `podscribe_export_${episodeIds.length}_episodes.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return { success: true, count: episodeIds.length };
    } catch (error) {
      console.error("Batch export error:", error);
      return { success: false, error: error.message || "Unknown error" };
    }
  };

  const refreshAfterRssImport = async (importedEpisodeIds) => {
    try {
      if (!importedEpisodeIds || importedEpisodeIds.length === 0) {
        return;
      }
      
      console.log(`Refreshing state after RSS import with ${importedEpisodeIds.length} episodes`);
      
      // Custom uploads podcast ID - this is where RSS episodes are stored
      const CUSTOM_UPLOADS_PODCAST_ID = 101;
      
      // Modified to use the correct API endpoint path with podcast ID
      const batchRequests = importedEpisodeIds.map(episodeId => ({
        id: `get-episode-${episodeId}`,
        method: "GET",
        url: `/library/podcast/${CUSTOM_UPLOADS_PODCAST_ID}/${episodeId}`
      }));
      
      const result = await executeBatch(batchRequests);
      
      if (!result.success) {
        console.error("Failed to fetch imported RSS episodes");
        toast.error("Episodes were imported but couldn't be displayed. Please refresh.");
        return;
      }
      
      const newEpisodes = result.responses
        .filter(res => res.status === 200)
        .map(res => ({
          ...res.body,
          isNew: true
        }));
      
      console.log(`Successfully fetched ${newEpisodes.length} imported episodes`);
      
      if (newEpisodes.length > 0) {
        setLibraryData(prevData => ({
          ...prevData,
          episodes: [...prevData.episodes, ...newEpisodes],
          episodeCount: prevData.episodeCount + newEpisodes.length
        }));
        
        toast.success(`Successfully imported ${newEpisodes.length} episodes to your library`);
      }
      
      return newEpisodes;
    } catch (error) {
      console.error("Error refreshing after RSS import:", error);
      toast.error("Error updating display after import");
      return [];
    }
  };

  return (
    <LibraryDataContext.Provider
      value={{
        LibraryData,
        setLibraryData,
        refreshLibraryEpisode,
        addLibraryEpisode,
        refreshLibrary,
        removeLibraryEpisode,
        fetchEpisodeCount,
        createFolder,
        updateFolder,
        deleteFolder,
        addEpisodeToFolder,
        removeEpisodeFromFolder,
        toggleStarEpisode,
        loadMoreEpisodes,
        removeMultipleEpisodes,
        addMultipleEpisodesToFolder,
        executeBatch,
        getEpisodeJobs,
        getUserJobs,
        cleanUserJobs, // Add the new function to the context
        batchExportEpisodes,
        refreshAfterRssImport, // Add the new function here
      }}
    >
      {children}
    </LibraryDataContext.Provider>
  );
};

export default LibraryDataContext;
