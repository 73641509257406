import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { MapIcon, DocumentTextIcon, FolderIcon, DownloadIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import Img from "../../UI/Img";
import { Container } from "./Container";

// Import background and screenshots from existing assets
import backgroundImage from "../../assets/background-faqs.jpg";
import mindMapImage from "../../assets/screenshots/mindmap.png";
import transcriptImage from "../../assets/screenshots/v2/transcript.png";
import folderImage from "../../assets/screenshots/v2/folders.png";
import exportImage from "../../assets/screenshots/v2/export.png";

const features = [
  {
    title: "Interactive Mind Maps",
    description:
      "See the structure and flow of ideas at a glance. Our AI generates mind maps to help you connect concepts within an episode visually.",
    color: "bg-indigo-500",
    icon: MapIcon,
    image: mindMapImage,
  },
  {
    title: "Interactive Transcripts",
    description:
      "Read accurate transcripts and click any section to instantly play the corresponding audio – perfect for verification or focused listening.",
    color: "bg-red-500",
    icon: DocumentTextIcon,
    image: transcriptImage,
  },
  {
    title: "Organized Knowledge Library",
    description:
      "Your processed episodes are automatically saved and organized. Use folders and efficient batch actions (like multi-select export or process) to manage your growing library.",
    color: "bg-yellow-500",
    icon: FolderIcon,
    image: folderImage,
  },
  {
    title: "Flexible Export Options",
    description:
      "Take your generated knowledge with you. Easily export mind maps (as PNG), summaries, notes, or full transcripts (e.g., Markdown, TXT) to integrate with your favorite tools or share with others.",
    color: "bg-green-500",
    icon: DownloadIcon,
    image: exportImage,
  },
];

export function SecondaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState("horizontal");
  let [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : features.length - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < features.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <section
      id="secondary-features"
      aria-label="Advanced features for podcast enthusiasts"
      className="relative py-20 sm:py-32 overflow-hidden bg-primary-600">
      <Img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
      />
      
      <Container className="relative">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">
            Enhance Your Audio Workflow
          </h2>
          <p className="mt-4 text-lg text-primary">
            Beyond the core analysis, PodScribe.IO offers tools to help you visualize, organize, integrate, and share
            your audio knowledge more effectively.
          </p>
        </div>

        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-start gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:gap-x-8 lg:pt-0"
          vertical={tabOrientation === "vertical"}
          selectedIndex={currentIndex}
          onChange={setCurrentIndex}>
          {({ selectedIndex }) => (
            <>
              {tabOrientation === "horizontal" && (
                <div className="relative z-10 lg:hidden">
                  <div className="absolute inset-0 flex items-center justify-between px-4">
                    <button
                      onClick={goToPrevious}
                      className="p-2 rounded-full bg-gray-800/10 text-primary hover:bg-gray-800/20">
                      <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button onClick={goToNext} className="p-2 rounded-full bg-gray-800/10 text-primary hover:bg-gray-800/20">
                      <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex justify-center items-center py-4">
                    <h3 className="text-primary text-lg font-semibold">{features[selectedIndex].title}</h3>
                  </div>
                </div>
              )}
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <p className="text-sm text-primary">{feature.description}</p>
                    </div>
                    <div className="mt-4 w-full overflow-hidden rounded-lg bg-gray-50 shadow-xl">
                      <Img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
              <div
                className={classNames(
                  "-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5",
                  tabOrientation === "horizontal" ? "hidden lg:flex" : ""
                )}>
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-4 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={classNames(
                        "group relative rounded-full px-4 py-1 lg:rounded-r-xl lg:rounded-l-none lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-gray-50 lg:bg-white/60 lg:ring-1 lg:ring-inset lg:ring-gray-200"
                          : "hover:bg-gray-50/50 lg:hover:bg-white/40"
                      )}>
                      <h3>
                        <Tab
                          className={classNames(
                            "font-display text-lg focus:outline-none",
                            selectedIndex === featureIndex
                              ? "text-primary"
                              : "text-primary hover:text-primary"
                          )}>
                          <span className="absolute inset-0 rounded-full lg:rounded-r-xl lg:rounded-l-none" />
                          <span className="flex items-center gap-4">
                            <feature.icon 
                              className={classNames(
                                "h-6 w-6",
                                selectedIndex === featureIndex ? feature.color.replace('bg-', 'text-') : "text-primary"
                              )} 
                            />
                            {feature.title}
                          </span>
                        </Tab>
                      </h3>
                      <p
                        className={classNames(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex ? "text-primary" : "text-primary group-hover:text-primary"
                        )}>
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}
