import Img from "../../UI/Img";
import { useState, useRef, useEffect } from "react";
import { Button } from "./Button";
import { Container } from "./Container";
import backgroundImage from "../../assets/background-faqs.jpg";
import { Await } from "react-router-dom";
import { axiosPublic } from "../../common/axiosPublic";
import { useForm } from "react-hook-form";

export function CallToAction() {
  // on Submit of the form call /api/newsletter/create
  // useRef to get the email address
  const emailRef = useRef(null);
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleNotifyMeSubmit = (data) => {
    setEmail(data.email);
    console.log(data);
  };

  // useEffect to create the contact via the API
  useEffect(() => {
    if (email !== "") {
      axiosPublic
        .post("/newsletter/create", { email: email })
        .then((response) => {
          console.log(response);
          setIsSubmitted(true);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [email]);

  return (
    <section id="get-started-today" className="relative overflow-hidden bg-primary-600 py-32">
      <Img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
      />
      <div
        className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-slate-50 opacity-90 pointer-events-none"
        aria-hidden="true"
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-primary sm:text-4xl">
            Turn Your Podcasts into Knowledge Assets Now
          </h2>
          <p className="mt-4 text-lg tracking-tight text-primary">
            Get ready to revolutionize your podcast listening experience. Register to transcribe, summarize, and extract
            valuable insights from your favorite podcasts.
          </p>
          <Button href="/register" color="primary" className="mt-10">
            Register and Transcribe Now
          </Button>
          <p className="mt-4 text-2xl tracking-tight text-primary">
            Not ready to join? No problem! Stay in the loop with our latest updates and offerings.
          </p>
          <form className="mx-auto mt-10 flex max-w-md gap-x-4" onSubmit={handleSubmit(handleNotifyMeSubmit)}>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              {...register("email", {
                required: true,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              })}
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="min-w-0 flex-auto rounded-full border-0 bg-primary/10 px-3.5 py-2 text-primary shadow-sm ring-1 ring-inset ring-primary/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              placeholder="Enter your email"
            />
            <Button type="submit" color="primary" className="">
              Notify me
            </Button>
          </form>
          {isSubmitted && <p className="mt-4 text-lg tracking-tight text-support-green">Thank you for subscribing!</p>}
        </div>
      </Container>
    </section>
  );
}
