import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getTracker } from "./common/tracker";
import Layout from "./components/Layout";
import Register from "./components/Register";
import { VerifyEmail } from "./components/Register";
import NotFound from "./components/NotFound";
import RequireAuthentication from "./components/RequireAuthentication";
import Library from "./components/Library";
import AskAiPage from "./components/AskAi/AskAiPage";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Refresh from "./components/Refresh";
import Profile from "./components/User/Profile";
import Subscription from "./components/User/Subscription";
import Terms from "./components/Landing/Terms";
import Home from "./components/index";
import Privacy from "./components/Landing/Privacy";
import Cookie from "./components/Landing/Cookie";
import Refund from "./components/Landing/Refund";
import PricingSuccess from "./components/User/PricingSuccess";
import CancelSubscription from "./components/User/CancelSubscription";
import PricingUpdateSuccess from "./components/User/PricingUpdateSuccess";
import RegistrationSuccess from "./components/User/RegistrationSuccess";
import ContentLayout from "./components/Landing/content/ContentLayout";
import PodcastGrid from "./components/Landing/content/ContentPodcasts";
import PodcastEpisodes from "./components/Landing/content/ContentPodcastEpisodes";
import ContentEpisodeNotes from "./components/Landing/content/ContentEpisodeNotes";
import { Blog } from "./components/Landing/blog/Blog";
import { BlogPost } from "./components/Landing/blog/BlogPost";
import DocumentationHome from "./components/Documentation/DocumentationHome";
import DocumentationPage from "./components/Documentation/DocumentationPage";

function App() {
  useEffect(() => {
    // Check consent before initializing tracker
    const consentGiven = localStorage.getItem('cookieConsent');
    const tracker = getTracker();
    
    // Only set user ID if consent hasn't been explicitly declined
    if (consentGiven !== 'declined') {
      tracker.setUserID("Visitor");
      console.log("Tracker initialized");
    } else {
      console.log("Tracking disabled due to user preferences");
    }
  }, []);

  return (
    <GoogleOAuthProvider 
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
      onScriptLoadError={() => console.error('Google OAuth script failed to load')}
      onScriptLoadSuccess={() => console.log('Google OAuth script loaded successfully')}>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/cookie-policy" element={<Cookie />} />
      <Route path="/refund-policy" element={<Refund />} />
      <Route path="login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="register" element={<Register />} />
      <Route path="/refresh" element={<Refresh />} />
      <Route path="/verifyemail/:ActivationCode" element={<VerifyEmail />} />
      <Route path="/registration-success" element={<RegistrationSuccess />} />
      <Route path="/content" element={<ContentLayout />}>
        <Route path="/content/podcasts" element={<PodcastGrid />} />
        <Route
          path="/content/podcasts/:podcastId/episodes"
          element={<PodcastEpisodes />}
        />
        <Route
          path="/content/podcasts/:podcastId/episode/:episodeId"
          element={<ContentEpisodeNotes />}
        />
      </Route>
      <Route element={<Layout />}>
        <Route element={<RequireAuthentication />}>
          <Route path="/library" element={<Library />}>
            <Route path=":episodeId" element={<Library />} />
          </Route>
          <Route path="/ask" element={<AskAiPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscription/" element={<Subscription />} />
          <Route path="/subscription/success" element={<PricingSuccess />} />
          <Route
            path="/subscription/update/success"
            element={<PricingUpdateSuccess />}
          />
          <Route path="/subscription/cancel" element={<CancelSubscription />} />
          <Route path="/documentation" element={<DocumentationHome />} />
          <Route path="/documentation/:slug" element={<DocumentationPage />} />
        </Route>
      </Route>
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:slug" element={<BlogPost />} />
      <Route path="/*" element={<NotFound />} />
      </Routes>
    </GoogleOAuthProvider>
  );
}

export default App;
