import { useEffect, useState } from "react";
import Img from "../../UI/Img";
import { Tab } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

import classNames from "classnames";

import { Container } from "./Container";
import backgroundImage from "../../assets/stacked-waves-haikei.svg";
import askai from "../../assets/screenshots/askai.png";
import mindmap from "../../assets/screenshots/mindmap.png";
import add_content from "../../assets/screenshots/add_content.png";
import main from "../../assets/screenshots/main.png";
import add from "../../assets/screenshots/v2/add.png";
import question_answer from "../../assets/screenshots/v2/question_answer.png";
import search from "../../assets/screenshots/v2/search.png";
import understand from "../../assets/screenshots/v2/understand.png";

const features = [
  {
    title: "Search Every Spoken Word",
    description:
      "Get accurate, searchable transcripts for all your audio. Instantly locate keywords, names, or specific moments without endless scrubbing. Perfect for quick referencing and research efficiency",
    image: search,
  },
  {
    title: "Grasp Key Ideas in Minutes",
    description:
      "Save hours with AI-generated summaries and key takeaways. Navigate long audio easily using automatic, time-stamped topic detection. Understand the essence of the content without listening to it all",
    image: understand,
  },
  {
    title: "Ask Your Audio Anything with AI Chat",
    description:
      "Go beyond simple search. Ask specific questions in natural language about one or multiple episodes and get immediate, cited answers synthesized directly from the content. Find exactly what you need, effortlessly.",
    image: question_answer,
  },
  {
    title: "Bring Your Own Audio (Public or Private)",
    description:
      " It's not just for public podcasts. Securely upload your private meetings, lectures, interviews, or link public audio/video files for analysis. Turn all your valuable audio into accessible knowledge.",
    image: add,
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState("horizontal");
  let [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : features.length - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < features.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-primary-400 pb-28 pt-20 sm:py-32">
      <div className="absolute inset-0 bg-black opacity-10 z-10"></div>
      <Img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%] z-0"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
      />
      <Container className="relative z-20">
        <div className="max-w-4xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Go Beyond Listening: Search, Ask, Understand Audio
          </h2>
          <p className="mt-6 text-lg tracking-tight text-primary-100">
            PodScribe.IO provides powerful AI tools to help you instantly search transcripts, get answers from audio
            content, generate summaries, and organize your learning efficiently. Process podcasts, lectures, meetings,
            and more - turning passive listening into active knowledge building
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-start gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:gap-x-8 lg:pt-0"
          vertical={tabOrientation === "vertical"}
          selectedIndex={currentIndex}
          onChange={setCurrentIndex}>
          {({ selectedIndex }) => (
            <>
              {tabOrientation === "horizontal" && (
                <div className="relative z-10 lg:hidden">
                  <div className="absolute inset-0 flex items-center justify-between px-4">
                    <button
                      onClick={goToPrevious}
                      className="p-2 rounded-full bg-white/10 text-white hover:bg-white/20">
                      <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button onClick={goToNext} className="p-2 rounded-full bg-white/10 text-white hover:bg-white/20">
                      <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex justify-center items-center py-4">
                    <h3 className="text-white text-lg font-semibold">{features[selectedIndex].title}</h3>
                  </div>
                </div>
              )}
              <div
                className={classNames(
                  "-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5 lg:sticky lg:top-4 lg:h-full",
                  tabOrientation === "horizontal" ? "hidden lg:flex" : ""
                )}>
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-4 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={classNames(
                        "group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                          : "hover:bg-white/10 lg:hover:bg-white/5"
                      )}>
                      <h3>
                        <Tab
                          className={classNames(
                            "font-display text-lg focus:outline-none",
                            selectedIndex === featureIndex
                              ? "text-primary-600 lg:text-white"
                              : "text-primary-100 hover:text-white lg:text-white"
                          )}>
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={classNames(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex ? "text-white" : "text-primary-100 group-hover:text-white"
                        )}>
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7 lg:h-full">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false} className="h-full">
                    <div className="flex flex-col h-full">
                      <div className="relative px-4 py-6 pb-10 mb-8 bg-white/10 rounded-xl lg:hidden">
                        <p className="text-base text-white text-center">{feature.description}</p>
                      </div>
                      <div className="w-full h-full max-w-[90rem] mx-auto overflow-hidden rounded-xl bg-white/20 backdrop-blur-sm border border-white/30 shadow-xl shadow-primary-900/20 lg:w-[67.8125rem] lg:flex lg:flex-col">
                        <div className="h-full relative">
                          <div className="absolute inset-0 bg-gradient-to-tr from-white/10 to-transparent opacity-30 z-10 rounded-xl pointer-events-none"></div>
                          <Img
                            className="w-full h-full object-cover object-left relative z-0"
                            src={feature.image}
                            alt=""
                            sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}
