import React, { useState } from 'react';
import useLibraryData from '../../hooks/useLibraryData';

const QueryOptions = ({ focus, onFocusChange, selectedEpisodes, onSelectedEpisodesChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { LibraryData } = useLibraryData();
  const [selectedEpisodeIds, setSelectedEpisodeIds] = useState([]);

  // Filter episodes based on search term
  const filteredEpisodes = LibraryData.episodes.filter(episode =>
    episode.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEpisodeSelect = (episodeId) => {
    setSelectedEpisodeIds(prev => {
      const newIds = prev.includes(episodeId) 
        ? prev.filter(id => id !== episodeId)
        : [...prev, episodeId];
      
      // Update parent component with selected episode IDs
      onSelectedEpisodesChange(newIds.join(','));
      return newIds;
    });
  };
  return (
    <div className="w-full bg-white p-6 rounded-lg border border-neutral-100 shadow-sm">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-neutral-700 mb-2">
            Focus Area
          </label>
          <div className="flex gap-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-primary-600 focus:ring-primary-500"
                name="focus"
                value="all"
                checked={focus === 'all'}
                onChange={(e) => onFocusChange(e.target.value)}
              />
              <span className="ml-2 text-sm text-neutral-700">All Episodes</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-primary-600 focus:ring-primary-500"
                name="focus"
                value="inbox"
                checked={focus === 'inbox'}
                onChange={(e) => onFocusChange(e.target.value)}
              />
              <span className="ml-2 text-sm text-neutral-700">Inbox</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-primary-600 focus:ring-primary-500"
                name="focus"
                value="selected"
                checked={focus === 'selected'}
                onChange={(e) => onFocusChange(e.target.value)}
              />
              <span className="ml-2 text-sm text-neutral-700">Selected Episodes</span>
            </label>
          </div>
        </div>

        {focus === 'selected' && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-neutral-700 mb-2">
                Search Episodes
              </label>
              <input
                type="text"
                className="block w-full rounded-md border-neutral-300 shadow-sm 
                           focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                placeholder="Search episode titles..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="max-h-60 overflow-y-auto border border-neutral-200 rounded-md">
              {filteredEpisodes.map(episode => (
                <label
                  key={episode.id}
                  className="flex items-center px-4 py-2 hover:bg-neutral-50 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-primary-600 rounded 
                             border-neutral-300 focus:ring-primary-500"
                    checked={selectedEpisodeIds.includes(episode.id)}
                    onChange={() => handleEpisodeSelect(episode.id)}
                  />
                  <span className="ml-2 text-sm text-neutral-700 truncate">
                    {episode.title}
                  </span>
                </label>
              ))}
              {filteredEpisodes.length === 0 && (
                <div className="px-4 py-2 text-sm text-neutral-500">
                  No episodes found
                </div>
              )}
            </div>
            
            <div className="text-sm text-neutral-500">
              {selectedEpisodeIds.length} episode{selectedEpisodeIds.length !== 1 ? 's' : ''} selected
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QueryOptions;
