import React from 'react';
import { Transition } from '@headlessui/react';
import { TypeAnimation } from 'react-type-animation';
import { 
  ChevronDownIcon, 
  ChevronUpIcon,
  SearchIcon,
  LightBulbIcon,
  DocumentTextIcon 
} from '@heroicons/react/outline';

const SourceText = ({ text, maxLength = 200 }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const shouldTruncate = text.length > maxLength;
  
  const displayText = isExpanded ? text : text.slice(0, maxLength) + (shouldTruncate ? '...' : '');

  return (
    <div>
      <div className="text-neutral-600">
        {displayText}
      </div>
      {shouldTruncate && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-primary-600 hover:text-primary-800 text-sm font-medium mt-2"
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

const formatTimestamp = (timestamp) => {
  if (!timestamp) return '';
  
  try {
    // Convert to number and round to nearest second
    const totalSeconds = Math.round(parseFloat(timestamp) * 60);
    
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    
    // Format with leading zeros
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return timestamp; // Return original timestamp if formatting fails
  }
};

const ResponseDisplay = ({ response, className = '' }) => {
  const [showSources, setShowSources] = React.useState(false);

  const toggleSources = () => {
    setShowSources(!showSources);
  };

  return (
    <Transition
      show={true}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      className={`bg-white rounded-xl shadow-sm border border-neutral-100 p-6 ${className}`}
    >
      {/* Query Section */}
      <div className="mb-6 pb-6 border-b border-neutral-100">
        <h3 className="text-sm font-medium text-neutral-500 flex items-center">
          <SearchIcon className="h-4 w-4 mr-2" />
          Question
        </h3>
        <p className="mt-2 text-lg text-neutral-900">{response.original_query}</p>
      </div>

      {/* Main Answer Section */}
      <div className="mb-6">
        <h3 className="text-sm font-medium text-neutral-500 flex items-center">
          <LightBulbIcon className="h-4 w-4 mr-2" />
          Answer
        </h3>
        <TypeAnimation
          sequence={[response.answer.main_answer]}
          wrapper="p"
          speed={50}
          className="mt-2 text-lg text-neutral-900 leading-relaxed"
        />
      </div>

      {/* Details Section */}
      {response.answer.details.length > 0 && (
        <div className="mb-6 pb-6 border-b border-neutral-100">
          <h3 className="text-sm font-medium text-neutral-500 flex items-center">
            <DocumentTextIcon className="h-4 w-4 mr-2" />
            Key Points
          </h3>
          <ul className="mt-2 space-y-2">
            {response.answer.details.map((detail, index) => (
              <li key={index} className="flex items-start">
                <span className="text-primary-600 mr-2">•</span>
                <span className="text-neutral-700">{detail}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Key Takeaway */}
      <div className="mb-6">
        <h3 className="text-sm font-medium text-neutral-500">Key Takeaway:</h3>
        <p className="mt-1 text-neutral-700">{response.answer.key_takeaway}</p>
      </div>

      {/* Sources Section */}
      {response.sources && response.sources.length > 0 && (
        <div className="mt-6 pt-6 border-t border-neutral-200">
          <button
            onClick={toggleSources}
            className="flex items-center justify-between w-full text-sm font-medium text-neutral-500 hover:text-neutral-700"
          >
            <span>Sources ({response.sources.length})</span>
            {showSources ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </button>
          
          {showSources && (
            <div className="mt-4 space-y-4">
              {response.sources.map((source, index) => (
                <div 
                  key={index}
                  className="bg-neutral-50 rounded-lg p-4 text-sm"
                >
                  {/* Source Header */}
                  <div className="flex items-center justify-between mb-2">
                    <div className="font-medium text-neutral-900">
                      {source.podcast_title} - {source.episode_title}
                    </div>
                    {source.similarity && (
                      <span className="text-xs px-2 py-1 bg-primary-50 text-primary-700 rounded-full">
                        {Math.round(source.similarity * 100)}% relevant
                      </span>
                    )}
                  </div>

                  {/* Source Content */}
                  <SourceText text={source.text} />

                  {/* Source Footer */}
                  {source.start_time && source.end_time && (
                    <div className="flex items-center mt-2 text-xs text-neutral-500">
                      <span className="inline-flex items-center px-2 py-1 bg-neutral-100 rounded-full">
                        {formatTimestamp(source.start_time)} → {formatTimestamp(source.end_time)}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </Transition>
  );
};

export default ResponseDisplay;
