// tracker.js
import Tracker from "@openreplay/tracker";

let trackerInstance = null;

// Comprehensive dummy tracker with all commonly used methods
const createDummyTracker = () => ({
  setUserID: () => {},
  start: () => {},
  stop: () => {},
  setMetadata: () => {},
  event: () => {},
  issue: () => {},
  isServiceWorkerInstalled: () => false,
  groupEnd: () => {},
  clearEssentials: () => {},
  setSessionToken: () => {},
  startNewSession: () => {},
  getSessionToken: () => null,
  getSessionID: () => null,
  getSessionURL: () => null,
  group: () => {},
  identify: () => {},
});

export const getTracker = () => {
  // Check for cookie consent
  const consentGiven = localStorage.getItem('cookieConsent');
  
  // Only initialize tracker if consent is given (or not yet decided)
  if (!trackerInstance && consentGiven !== 'declined') {
    trackerInstance = new Tracker({
      projectKey: "9L6t2QxYKKysYw9w7O9f",
    });
    trackerInstance.start();
  }
  
  // Return dummy tracker if consent is declined
  if (consentGiven === 'declined') {
    return createDummyTracker();
  }
  
  return trackerInstance || createDummyTracker();
};

// Add a function to clear tracker when consent is withdrawn
export const clearTracker = () => {
  if (trackerInstance) {
    trackerInstance.stop();
    trackerInstance = null;
  }
};
