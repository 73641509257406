import React from 'react';

const LoadingState = () => {
  return (
    <div className="space-y-4 mt-8">
      <div className="animate-pulse">
        <div className="h-4 bg-neutral-200 rounded w-3/4 mb-4" />
        <div className="h-4 bg-neutral-200 rounded w-1/2" />
      </div>
      <div className="animate-pulse">
        <div className="h-4 bg-neutral-200 rounded w-5/6 mb-4" />
        <div className="h-4 bg-neutral-200 rounded w-2/3" />
      </div>
    </div>
  );
};

export default LoadingState;
