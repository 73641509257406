import React from "react";
import { TrashIcon, FolderIcon, DocumentTextIcon } from "@heroicons/react/outline";
import { LightningBoltIcon } from "@heroicons/react/solid";
import classNames from "classnames";

export default function MultiOperationHeader({ 
  selectedCount,
  totalCount,
  onSelectAll,
  onClearSelection,
  onDelete,
  onAddToFolder,
  onExport,
  onPodscribe
}) {
  return (
    <div className="border-t border-b border-neutral-200 bg-neutral-100 px-3 sm:px-6 py-2 text-sm font-medium text-neutral-500">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between space-y-2 sm:space-y-0">
        <div className="flex items-center space-x-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={selectedCount > 0 && selectedCount === totalCount}
              onChange={(e) => onSelectAll(e.target.checked)}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 rounded"
            />
            <span className="text-sm text-neutral-700 truncate">
              {selectedCount > 0 ? `${selectedCount} selected` : "Select All"}
            </span>
          </label>
          
          {selectedCount > 0 && (
            <button
              onClick={onClearSelection}
              className="text-xs text-primary-600 hover:text-primary-700"
            >
              Clear
            </button>
          )}
        </div>
        
        {selectedCount > 0 && (
          <div className="flex flex-wrap items-center gap-1 sm:gap-2">
            <button
              onClick={() => {
                console.log("Delete button clicked in MultiOperationHeader");
                onDelete();
              }}
              className="p-1.5 sm:p-2 rounded-md hover:bg-neutral-200 transition-colors text-neutral-500"
              title="Delete selected"
            >
              <TrashIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              <span className="sr-only">Delete</span>
            </button>
            <button
              onClick={onAddToFolder}
              className="p-1.5 sm:p-2 rounded-md hover:bg-neutral-200 transition-colors text-neutral-500"
              title="Add to folder"
            >
              <FolderIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              <span className="sr-only">Add to folder</span>
            </button>
            <button
              onClick={onExport}
              className="p-1.5 sm:p-2 rounded-md hover:bg-neutral-200 transition-colors text-neutral-500"
              title="Export selected"
            >
              <DocumentTextIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              <span className="sr-only">Export</span>
            </button>
            <button
              onClick={onPodscribe}
              className="p-1.5 sm:p-2 rounded-md hover:bg-neutral-200 transition-colors text-neutral-500"
              title="Process selected episodes"
            >
              <LightningBoltIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              <span className="sr-only">Podscribe</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
