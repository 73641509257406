import React, { useEffect, useState, useReducer, useContext, useRef } from "react";
import { PlusIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import { InformationCircleIcon } from "@heroicons/react/solid";
import Button from "../../../UI/Button";
import PodcastCard from "../../../UI/PodcastCard";
import { axiosPrivate } from "../../../common/axiosPrivate";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useDebounceValue } from "usehooks-ts";
import SearchInput from "../../../UI/SearchComponent";
import PodcastProfileHeadingCard from "../../../UI/PodcastProfileHeadingCard";
import PodcastEpisodeCard from "../../../UI/PodcastEpisodeCard";
import LibraryDataContext from "../../../context/LibraryDataProvider";
import { toast } from "react-hot-toast";

const FETCH_DATA = "FETCH_DATA";
const FILTER_FEEDS = "FILTER_FEEDS";
const SHOW_MORE = "SHOW_MORE";
const FETCH_EPISODES = "FETCH_EPISODES";
const SELECT_EPISODE = "SELECT_EPISODE";
const TOGGLE_SELECT_ALL = "TOGGLE_SELECT_ALL";
const CLEAR_SELECTION = "CLEAR_SELECTION";
const BATCH_UPDATE_EPISODES = "BATCH_UPDATE_EPISODES";

const initialState = {
  feeds: [],
  filteredFeeds: [],
  displayCount: 50,
  selectedPodcast: null,
  episodes: [],
  currentPage: 1,
  totalPages: 1,
  itemsPerPage: 50,
  totalItems: 0,
  selectedEpisodes: new Set(),
};

const feedsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        feeds: action.payload,
      };
    case FILTER_FEEDS:
      if (action.payload) {
        return {
          ...state,
          filteredFeeds: state.feeds
            .filter((feed) => feed.title.toLowerCase().includes(action.payload.toLowerCase()))
            .sort((a, b) => b.is_favorite - a.is_favorite),
        };
      }
      return {
        ...state,
        filteredFeeds: [...state.feeds].sort((a, b) => b.is_favorite - a.is_favorite).slice(0, state.displayCount),
      };
    case "FAVORITE":
      const feedId = action.payload.feedId;
      const isFavorite = action.payload.isFavorite;
      const feeds = state.feeds.map((feed) => {
        if (feed.id === feedId) {
          return { ...feed, is_favorite: isFavorite };
        }
        return feed;
      });
      return {
        ...state,
        feeds,
      };
    case SHOW_MORE:
      return {
        ...state,
        displayCount: state.displayCount + 5,
      };
    case "SELECT_PODCAST":
      return {
        ...state,
        selectedPodcast: action.payload,
        currentPage: 1,
        selectedEpisodes: new Set(), // Clear selection when changing podcast
      };
    case FETCH_EPISODES:
      return {
        ...state,
        episodes: action.payload.episodes,
        currentPage: action.payload.page,
        totalPages: action.payload.pages,
        itemsPerPage: action.payload.size,
        totalItems: action.payload.total,
        selectedEpisodes: new Set(), // Clear selection when new episodes are fetched
      };
    case "UPDATE_EPISODE":
      return {
        ...state,
        episodes: state.episodes.map((episode) =>
          episode.id === action.payload.episodeId
            ? { ...episode, is_added_to_library: action.payload.isAdded }
            : episode
        ),
      };
    case "CHANGE_PAGE":
      return {
        ...state,
        currentPage: action.payload,
        selectedEpisodes: new Set(), // Clear selection when changing page
      };
    case SELECT_EPISODE: {
      const newSelectedEpisodes = new Set(state.selectedEpisodes);
      if (action.payload.selected) {
        newSelectedEpisodes.add(action.payload.episodeId);
      } else {
        newSelectedEpisodes.delete(action.payload.episodeId);
      }
      return {
        ...state,
        selectedEpisodes: newSelectedEpisodes,
      };
    }
    case TOGGLE_SELECT_ALL: {
      // If all are currently selected, clear selection; otherwise select all
      const allSelected =
        state.episodes.length > 0 && state.episodes.every((episode) => state.selectedEpisodes.has(episode.id));

      if (allSelected) {
        return {
          ...state,
          selectedEpisodes: new Set(),
        };
      } else {
        const allEpisodeIds = state.episodes.map((episode) => episode.id);
        return {
          ...state,
          selectedEpisodes: new Set(allEpisodeIds),
        };
      }
    }
    case CLEAR_SELECTION:
      return {
        ...state,
        selectedEpisodes: new Set(),
      };
    case BATCH_UPDATE_EPISODES:
      return {
        ...state,
        episodes: state.episodes.map((episode) => {
          if (action.payload.episodeIds.includes(episode.id)) {
            return {
              ...episode,
              is_added_to_library: action.payload.isAdded,
            };
          }
          return episode;
        }),
        selectedEpisodes: new Set(), // Clear selection after batch operation
      };
    default:
      return state;
  }
};

export default function FavoritesTab({ onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(feedsReducer, initialState);
  const [filterTerm, setFilterTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const [isBatchProcessing, setIsBatchProcessing] = useState(false);
  const { addLibraryEpisode, removeLibraryEpisode, executeBatch, setLibraryData } = useContext(LibraryDataContext);

  const handlePodcastClick = async (podcast) => {
    dispatch({ type: "SELECT_PODCAST", payload: podcast });
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/podcast/${podcast.id}/episodes?page=1&size=${state.itemsPerPage}`);
      dispatch({
        type: FETCH_EPISODES,
        payload: {
          episodes: response.data.items,
          page: response.data.page,
          pages: response.data.pages,
          size: response.data.size,
          total: response.data.total,
        },
      });
      // Scroll to top of episodes container
      if (episodesContainerRef.current) {
        episodesContainerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Error fetching episodes: ", error);
    }
    setIsLoading(false);
  };

  const episodesContainerRef = useRef(null);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= state.totalPages) {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(
          `/podcast/${state.selectedPodcast.id}/episodes?page=${newPage}&size=${state.itemsPerPage}`
        );
        dispatch({
          type: FETCH_EPISODES,
          payload: {
            episodes: response.data.items,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          },
        });
        // Scroll to top of episodes container
        episodesContainerRef.current?.scrollIntoView();
      } catch (error) {
        console.error("Error fetching episodes: ", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(`/podcast/favorites`);
        dispatch({ type: FETCH_DATA, payload: response.data.podcasts });
        dispatch({ type: FILTER_FEEDS });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching favorites: ", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFavoriteOnClick = async (feedId, isFavorite) => {
    try {
      await axiosPrivate.post(`/podcast/favorites/${feedId}`, {
        is_favorite: isFavorite,
      });
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
    setFilterTerm("");
    dispatch({ type: "FAVORITE", payload: { feedId, isFavorite } });
    dispatch({ type: FILTER_FEEDS });
  };

  const handleShowMore = () => {
    dispatch({ type: SHOW_MORE });
    dispatch({ type: FILTER_FEEDS });
  };

  const handleSearch = (e) => {
    setDebouncedSearchValue(e.target.value);
    setFilterTerm(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: FILTER_FEEDS, payload: debouncedSearchValue });
  }, [debouncedSearchValue]);

  const handleBack = () => {
    dispatch({ type: "SELECT_PODCAST", payload: null });
  };

  const handleAddRemove = async (feedId, episodeId, isAdded) => {
    if (isAdded) {
      await addLibraryEpisode(feedId, episodeId);
    } else {
      await removeLibraryEpisode(feedId, episodeId);
    }
    // Update the local state to reflect the change
    dispatch({ type: "UPDATE_EPISODE", payload: { episodeId, isAdded } });
  };

  const handleSelectEpisode = (episodeId, selected) => {
    dispatch({ type: SELECT_EPISODE, payload: { episodeId, selected } });
  };

  const handleSelectAll = () => {
    dispatch({ type: TOGGLE_SELECT_ALL });
  };

  const handleClearSelection = () => {
    dispatch({ type: CLEAR_SELECTION });
  };

  const handleBatchAdd = async () => {
    if (state.selectedEpisodes.size === 0) {
      toast.error("No episodes selected");
      return;
    }

    setIsBatchProcessing(true);
    try {
      const selectedEpisodeIds = Array.from(state.selectedEpisodes);
      const selectedEpisodes = state.episodes.filter((ep) => selectedEpisodeIds.includes(ep.id));

      // Prepare batch requests
      const batchRequests = selectedEpisodes.map((episode) => ({
        id: `add-episode-${episode.id}`,
        method: "POST",
        url: `/library/${state.selectedPodcast.id}/${episode.id}`,
      }));

      // Execute batch operation
      const result = await executeBatch(batchRequests);

      if (result.success) {
        // Update local state in the component
        dispatch({
          type: BATCH_UPDATE_EPISODES,
          payload: {
            episodeIds: selectedEpisodeIds,
            isAdded: true,
          },
        });

        // Force re-render the list by updating each episode's state individually
        selectedEpisodes.forEach((episode) => {
          // This ensures each card component gets the updated state
          episode.is_added_to_library = true;
        });

        // Update the LibraryDataContext to make episodes visible across the application
        // Process successful responses to get enriched episode data from the server
        const successfulResponses = result.responses
          .filter((response) => response.status >= 200 && response.status < 300)
          .map((response) => response.body);

        // If we have response data, use it to update the LibraryDataContext with complete episode data
        if (successfulResponses.length > 0) {
          // Add episodes to the LibraryDataContext
          setLibraryData((prevData) => {
            // Create new episodes with isNew flag to highlight them in the UI
            const newLibraryEpisodes = successfulResponses.map((episodeData) => ({
              ...episodeData,
              isNew: true,
            }));

            // Add the new episodes to the existing episodes in LibraryDataContext
            return {
              ...prevData,
              episodes: [...prevData.episodes, ...newLibraryEpisodes],
              episodeCount: prevData.episodeCount + newLibraryEpisodes.length,
            };
          });
        }

        toast.success(`Added ${selectedEpisodeIds.length} episodes to your library`);

        // Close the modal after successful batch operation if onClose is provided
        if (onClose && typeof onClose === "function") {
          onClose();
        }
      } else {
        toast.error("Failed to add episodes to library");
      }
    } catch (error) {
      console.error("Error in batch add operation:", error);
      toast.error("An error occurred while adding episodes");
    } finally {
      setIsBatchProcessing(false);
    }
  };

  const selectionCount = state.selectedEpisodes.size;
  const allSelected =
    state.episodes.length > 0 && state.episodes.every((episode) => state.selectedEpisodes.has(episode.id));

  if (state.selectedPodcast) {
    return (
      <div className="h-full overflow-y-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <Button onClick={handleBack} className="mb-4">
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            Back to Favorites
          </Button>
          <PodcastProfileHeadingCard feed={state.selectedPodcast} handleFavorite={handleFavoriteOnClick} />

          {/* User instructions for batch selection */}
          <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md my-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-blue-700">
                  Quickly add multiple episodes from your favorite podcasts. Select episodes using the checkboxes, then
                  click "Add Selected" to add them all at once.
                </p>
              </div>
            </div>
          </div>

          {/* Batch selection controls */}
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 bg-neutral-50 p-3 rounded-md border border-neutral-200 my-4">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="select-all"
                checked={allSelected}
                onChange={handleSelectAll}
                className="h-5 w-5 rounded border-neutral-300 text-primary-600 focus:ring-primary-500"
              />
              <label htmlFor="select-all" className="text-sm font-medium text-neutral-700">
                {allSelected ? "Deselect All" : "Select All"}
              </label>
            </div>

            <div className="flex flex-wrap items-center gap-3">
              {selectionCount > 0 && (
                <>
                  <span className="text-sm text-neutral-600">{selectionCount} selected</span>
                  <button
                    onClick={handleClearSelection}
                    className="text-sm text-neutral-500 hover:text-neutral-700 px-2 py-1">
                    Clear
                  </button>
                  <button
                    onClick={handleBatchAdd}
                    disabled={isBatchProcessing || selectionCount === 0}
                    className="inline-flex items-center justify-center w-full sm:w-auto px-3 py-2 text-sm font-medium rounded-md 
                              bg-primary-600 text-white hover:bg-primary-700 focus:outline-none 
                              focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                              disabled:opacity-50 disabled:cursor-not-allowed">
                    {isBatchProcessing ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24">
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </>
                    ) : (
                      <>Add Selected</>
                    )}
                  </button>
                </>
              )}
            </div>
          </div>

          <h2 ref={episodesContainerRef} className="text-xl font-bold text-left text-primary-600 mt-4">
            Episodes
          </h2>
          {isLoading && state.episodes.length === 0 ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="grid gap-6 mt-4">
                {state.episodes.map((episode, index) => (
                  <div key={index} className="w-full">
                    <PodcastEpisodeCard
                      episode={episode}
                      handleAddRemove={handleAddRemove}
                      onCloseModal={onClose}
                      isSelectable={true}
                      isSelected={state.selectedEpisodes.has(episode.id)}
                      onSelectChange={(episodeId, selected) => handleSelectEpisode(episodeId, selected)}
                    />
                  </div>
                ))}
              </div>
              <nav className="flex flex-col sm:flex-row items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-6 sticky bottom-0 shadow-lg">
                <div className="text-sm text-neutral-500 text-center sm:text-left">
                  <p className="sm:inline hidden">
                    Showing <span className="font-medium">{(state.currentPage - 1) * state.itemsPerPage + 1}</span> to{" "}
                    <span className="font-medium">
                      {Math.min(state.currentPage * state.itemsPerPage, state.totalItems)}
                    </span>{" "}
                    of <span className="font-medium">{state.totalItems}</span> results
                  </p>
                  {/* Mobile pagination info */}
                  <p className="sm:hidden">
                    Page <span className="font-medium">{state.currentPage}</span> of{" "}
                    <span className="font-medium">{state.totalPages}</span>
                  </p>
                </div>
                <div className="flex justify-between w-full sm:w-auto gap-2 mt-3 sm:mt-0">
                  <button
                    onClick={() => handlePageChange(state.currentPage - 1)}
                    disabled={isLoading || state.currentPage === 1}
                    className="relative inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed min-w-[90px]">
                    Previous
                  </button>
                  <button
                    onClick={() => handlePageChange(state.currentPage + 1)}
                    disabled={isLoading || state.currentPage === state.totalPages}
                    className="relative inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed min-w-[90px]">
                    Next
                  </button>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full overflow-y-auto px-4 sm:px-6 lg:px-8">
      {isLoading && <LoadingSpinner />}
      <div className="max-w-3xl mx-auto">
        <SearchInput
          value={filterTerm}
          onChange={handleSearch}
          className="mb-6 w-full"
          placeholder="Search favorite podcasts..."
        />
        {!isLoading && state.feeds.length === 0 && (
          <div className="text-center mt-8">
            <h3 className="text-lg text-neutral-400">
              You have no favorite podcasts yet. Use the search tab to find podcasts to favorite.
            </h3>
          </div>
        )}
        <div className="grid gap-6">
          {!isLoading &&
            state.filteredFeeds.map((feed, index) => (
              <PodcastCard
                key={index}
                feed={feed}
                handleFavorite={handleFavoriteOnClick}
                onClick={handlePodcastClick}
              />
            ))}
        </div>
        {!filterTerm && !isLoading && state.displayCount < state.feeds.length && (
          <div className="mt-8 text-center">
            <Button type="button" onClick={handleShowMore} className="py-2 px-4 gap-x-2">
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
