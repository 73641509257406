import React, { useState } from "react";
import { ChatAltIcon, LightBulbIcon, SearchIcon, XCircleIcon } from "@heroicons/react/outline";
import QueryInput from "./QueryInput";
import ResponseDisplay from "./ResponseDisplay";
import LoadingState from "./LoadingState";
import QueryOptions from "./QueryOptions";
import { axiosPrivate } from "../../common/axiosPrivate";
import SEO from "../SEO";
import PodcastIcon from "../../UI/PodcastIcon";

const AskAiPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [queryFocus, setQueryFocus] = useState("inbox");
  const [error, setError] = useState(null);
  const [selectedEpisodes, setSelectedEpisodes] = useState("");
  const [showOptions, setShowOptions] = useState(false);

  const handleQuery = async (query, previousQuery = null, previousResponse = null) => {
    setIsLoading(true);
    setError(null); // Clear previous errors
    try {
      const response = await axiosPrivate.post("/rag/query", {
        query,
        previous_query: previousQuery,
        previous_response: previousResponse?.answer?.main_answer,
        focus: queryFocus,
        selected_episodes: queryFocus === "selected" ? selectedEpisodes.split(",").map((ep) => ep.trim()) : undefined,
      });

      const newResponse = response.data;
      setConversation((prev) => [...prev, { query, response: newResponse }]);
    } catch (error) {
      console.error("Query failed:", error);
      if (error.response?.status === 402) {
        setError("You have run out of AI message credits. Please upgrade your plan to continue asking questions.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SEO title="PodScribe.IO | AskAI" />
      <div className="flex-1 flex lg:mt-3 lg:ml-3 lg:mr-3 rounded-lg flex-col h-full pb-10 bg-gradient-to-b from-neutral-50 to-white rounded-t-lg">
        <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300">
          <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {/* Initial Query Section */}
            <section className="bg-white rounded-xl shadow-sm border border-neutral-100 p-8 mb-8 relative">
              {/* Add reset button */}
              {(conversation.length > 0 || showOptions) && (
                <button
                  onClick={() => {
                    setConversation([]);
                    setShowOptions(false);
                    setQueryFocus("inbox");
                    setSelectedEpisodes("");
                  }}
                  className="absolute top-4 right-4 p-2 text-neutral-400 hover:text-neutral-600 transition-colors rounded-full hover:bg-neutral-50"
                  title="Reset conversation">
                  <XCircleIcon className="h-6 w-6" />
                </button>
              )}
              <div className="flex flex-col items-center justify-center max-w-2xl mx-auto">
                <PodcastIcon className="h-16 w-16 fill-primary-600 mb-8" />
                <h2 className="text-3xl font-bold text-neutral-900 mb-3 text-center">Ask AI About Your Podcasts</h2>
                <p className="text-neutral-600 mb-8 text-center max-w-lg">
                  Get instant insights from your podcast library using AI-powered semantic search. Ask questions,
                  explore topics, and discover connections across episodes.
                </p>

                <div className="w-full space-y-6">
                  <QueryInput
                    onSubmit={(query) => {
                      setConversation([]);
                      handleQuery(query);
                    }}
                    disabled={isLoading}
                  />

                  {error && (
                    <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-600">{error}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex justify-center">
                    <button
                      onClick={() => setShowOptions(!showOptions)}
                      className="inline-flex items-center px-4 py-2 text-sm text-neutral-600 hover:text-neutral-900 transition-colors rounded-lg hover:bg-neutral-50">
                      <SearchIcon className="h-4 w-4 mr-2" />
                      <span>{showOptions ? "Hide Search Options" : "Show Search Options"}</span>
                    </button>
                  </div>

                  {showOptions && (
                    <div className="transition-all duration-200 ease-in-out">
                      <QueryOptions
                        focus={queryFocus}
                        onFocusChange={setQueryFocus}
                        selectedEpisodes={selectedEpisodes}
                        onSelectedEpisodesChange={setSelectedEpisodes}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>

            {/* Conversation Flow */}
            {conversation.length > 0 && (
              <section className="space-y-8">
                {conversation.map((item, index) => (
                  <div key={index} className="space-y-8">
                    <ResponseDisplay response={item.response} />

                    {/* Show follow-up input only after the last response */}
                    {index === conversation.length - 1 && !isLoading && (
                      <div className="bg-white p-6 rounded-xl border border-neutral-100 shadow-sm">
                        <div className="mb-4">
                          <h3 className="text-sm font-medium text-neutral-700 flex items-center">
                            <ChatAltIcon className="h-5 w-5 mr-2 text-primary-600" />
                            Continue the Conversation
                          </h3>
                          <p className="text-sm text-neutral-500 mt-1">
                            Ask a follow-up question or explore a related topic based on the previous response.
                          </p>
                        </div>
                        <QueryInput
                          onSubmit={(query) => handleQuery(query, item.query, item.response)}
                          disabled={isLoading}
                          placeholder="What else would you like to know about this topic?"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </section>
            )}

            {/* Loading State */}
            {isLoading && (
              <div className="bg-white p-6 rounded-xl border border-neutral-100 shadow-sm">
                <LoadingState />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AskAiPage;
