import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import GhostContentAPI from "@tryghost/content-api";
import { FadeLoader } from "react-spinners";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import SEO from "../SEO";

const api = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_API_URL,
  key: process.env.REACT_APP_GHOST_CONTENT_API_KEY,
  version: "v5.0",
});

export default function DocumentationPage() {
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    api.pages
      .read({ slug }, { include: "tags,authors" })
      .then((response) => {
        setPage(response);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <div className="flex-1 flex lg:mt-3 lg:ml-3 lg:mr-3 rounded-lg flex-col h-full pb-10 bg-gradient-to-b from-neutral-50 to-white rounded-t-lg">
        <div className="flex justify-center items-center min-h-screen">
          <FadeLoader color="#4055A8" />
        </div>
      </div>
    );
  }

  if (!page) {
    return (
      <div className="flex-1 flex flex-col h-full pb-10 bg-white lg:mt-3 lg:ml-3 lg:mr-3 lg:rounded-t-lg border border-neutral-100 shadow-sm">
        <div className="bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div>
              <h1 className="text-2xl font-bold text-primary-600">Documentation</h1>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col overflow-y-auto">
          <div className="w-full max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="text-center py-12">
              <p className="text-lg text-neutral-700">Documentation page not found.</p>
              <Link 
                to="/documentation" 
                className="mt-4 inline-flex items-center text-primary-600 hover:text-primary-800 font-medium"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" />
                Back to Documentation
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO title={`${page.title} | PodScribe.IO Documentation`} description={page.excerpt || page.meta_description} />
      <div className="flex-1 flex flex-col h-full pb-10 bg-white lg:mt-3 lg:ml-3 lg:mr-3 lg:rounded-t-lg border border-neutral-100 shadow-sm">
        <div className="bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div>
              <Link 
                to="/documentation" 
                className="inline-flex items-center text-primary-600 hover:text-primary-800 font-medium mb-2"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" />
                Back to Documentation
              </Link>
              <h1 className="text-2xl font-bold text-primary-600">{page.title}</h1>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300">
          <div className="w-full max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <article>
              {page.feature_image && (
                <img 
                  src={page.feature_image} 
                  alt={page.title} 
                  className="w-full h-64 object-cover rounded-lg mb-8 shadow-sm"
                />
              )}
              
              {page.excerpt && (
                <div className="mb-8 text-lg text-neutral-700 font-medium italic border-l-4 border-primary-300 pl-4 py-2 bg-primary-50 rounded-r-md">
                  {page.excerpt}
                </div>
              )}
              
              {page.primary_author && (
                <div className="flex items-center mb-8">
                  {page.primary_author.profile_image && (
                    <img
                      src={page.primary_author.profile_image}
                      alt={page.primary_author.name}
                      className="w-12 h-12 rounded-full object-cover mr-4"
                    />
                  )}
                  <div className="text-left">
                    <p className="text-md font-semibold text-primary-900">
                      {page.primary_author.name}
                    </p>
                    <p className="text-neutral-500 text-sm">
                      {new Date(page.published_at).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              )}
              
              <div className="prose prose-primary max-w-none">
                <div dangerouslySetInnerHTML={{ __html: page.html }}></div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}
