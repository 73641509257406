import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { PlusIcon, FolderIcon } from "@heroicons/react/solid";
import * as OutlineIcons from "@heroicons/react/outline";
import * as SolidIcons from "@heroicons/react/solid";
import classNames from "classnames";
import useLibraryData from "../../hooks/useLibraryData";
import toast from "react-hot-toast";

const getIconComponent = (iconName, className = "h-5 w-5") => {
  const OutlineComponent = OutlineIcons[iconName];
  const SolidComponent = SolidIcons[iconName];
  const IconComponent = OutlineComponent || SolidComponent;

  if (IconComponent) {
    return <IconComponent className={className} />;
  }
  return <OutlineIcons.FolderIcon className={className} />;
};

export default function BatchFolderModal({ show, onClose, selectedEpisodeIds, onSuccess }) {
  const { LibraryData, addMultipleEpisodesToFolder, createFolder } = useLibraryData();
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [newFolderData, setNewFolderData] = useState({ name: "", icon: "FolderIcon" });
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Reset state when modal opens
  useEffect(() => {
    if (show) {
      setSelectedFolderId(null);
      setIsCreatingFolder(false);
      setNewFolderData({ name: "", icon: "FolderIcon" });
      setIsProcessing(false);
    }
  }, [show]);

  const availableIcons = [
    "FolderIcon", "BookmarkIcon", "StarIcon", "HeartIcon", "MusicNoteIcon",
    "DocumentTextIcon", "LightBulbIcon", "TagIcon", "AcademicCapIcon"
  ];

  const handleAddToFolder = async () => {
    if (!selectedFolderId && !isCreatingFolder) {
      toast.error("Please select or create a folder");
      return;
    }

    setIsProcessing(true);
    const toastId = toast.loading(`Adding ${selectedEpisodeIds.length} episodes to folder...`);
    
    try {
      let targetFolderId = selectedFolderId;
      
      // If creating a new folder, do that first
      if (isCreatingFolder) {
        if (!newFolderData.name.trim()) {
          toast.error("Please enter a folder name", { id: toastId });
          setIsProcessing(false);
          return;
        }
        
        const newFolder = await createFolder(newFolderData);
        targetFolderId = newFolder.id;
      }
      
      // Add all selected episodes to the folder
      const result = await addMultipleEpisodesToFolder(targetFolderId, selectedEpisodeIds);
      
      if (result.success) {
        if (result.skipped > 0 && result.added === 0) {
          toast.success(`All episodes were already in the folder`, { id: toastId });
        } else if (result.skipped > 0) {
          toast.success(`Added ${result.added} episodes to folder (${result.skipped} were already there)`, { id: toastId });
        } else {
          toast.success(`Added ${result.added} episodes to folder`, { id: toastId });
        }
        onClose();
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess();
        }
      } else {
        toast.error(result.message || "Failed to add episodes to folder", { id: toastId });
      }
    } catch (error) {
      console.error("Error adding episodes to folder:", error);
      toast.error("Failed to add episodes to folder", { id: toastId });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary-100">
                  <FolderIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-neutral-900">
                    Add Episodes to Folder
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-neutral-500">
                      {`Select a folder to add ${selectedEpisodeIds.length} episode${selectedEpisodeIds.length !== 1 ? 's' : ''}.`}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-6">
                <div className="space-y-4">
                  {isCreatingFolder ? (
                    <div className="space-y-3">
                      <label htmlFor="folder-name" className="block text-sm font-medium text-neutral-700">
                        New Folder Name
                      </label>
                      <input
                        type="text"
                        name="folder-name"
                        id="folder-name"
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-neutral-300 rounded-md"
                        placeholder="Enter folder name"
                        value={newFolderData.name}
                        onChange={(e) => setNewFolderData({ ...newFolderData, name: e.target.value })}
                      />
                      
                      <div>
                        <label className="block text-sm font-medium text-neutral-700 mb-2">
                          Select Icon
                        </label>
                        <div className="grid grid-cols-5 gap-2">
                          {availableIcons.map((icon) => (
                            <button
                              key={icon}
                              type="button"
                              onClick={() => setNewFolderData({ ...newFolderData, icon })}
                              className={classNames(
                                "flex items-center justify-center h-10 w-10 rounded-md",
                                newFolderData.icon === icon
                                  ? "bg-primary-100 ring-2 ring-primary-500"
                                  : "bg-neutral-100 hover:bg-neutral-200"
                              )}
                            >
                              {getIconComponent(icon, "h-5 w-5 text-neutral-700")}
                            </button>
                          ))}
                        </div>
                      </div>
                      
                      <div className="flex justify-between mt-3">
                        <button
                          type="button"
                          className="inline-flex items-center px-3 py-2 border border-neutral-300 shadow-sm text-sm leading-4 font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                          onClick={() => setIsCreatingFolder(false)}
                        >
                          Back to Folders
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <h4 className="text-sm font-medium text-neutral-700">Select Folder</h4>
                        <div className="mt-2 max-h-60 overflow-y-auto space-y-1 scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent">
                          {LibraryData.folders && LibraryData.folders.length > 0 ? (
                            LibraryData.folders.map((folder) => (
                              <button
                                key={folder.id}
                                type="button"
                                onClick={() => setSelectedFolderId(folder.id)}
                                className={classNames(
                                  "w-full text-left px-3 py-2 rounded-md flex items-center space-x-2",
                                  selectedFolderId === folder.id
                                    ? "bg-primary-100 text-primary-700"
                                    : "text-neutral-700 hover:bg-neutral-50"
                                )}
                              >
                                {getIconComponent(folder.icon || "FolderIcon")}
                                <span>{folder.name}</span>
                                <span className="ml-auto text-xs text-neutral-500">
                                  {folder.episodes?.length || 0} episodes
                                </span>
                              </button>
                            ))
                          ) : (
                            <p className="text-sm text-neutral-500 py-2">No folders yet. Create one below.</p>
                          )}
                        </div>
                      </div>
                      
                      <button
                        type="button"
                        onClick={() => setIsCreatingFolder(true)}
                        className="inline-flex items-center px-3 py-2 border border-neutral-300 shadow-sm text-sm leading-4 font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                        Create New Folder
                      </button>
                    </>
                  )}
                </div>

                <div className="flex space-x-3 mt-5">
                  <button
                    type="button"
                    className="flex-1 justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-neutral-700 bg-neutral-100 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={isProcessing || (!selectedFolderId && (!isCreatingFolder || !newFolderData.name))}
                    className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-neutral-300 disabled:cursor-not-allowed"
                    onClick={handleAddToFolder}
                  >
                    {isProcessing && (
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                    )}
                    Add to {isCreatingFolder ? "New" : "Selected"} Folder
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
