import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { LightningBoltIcon, ExclamationIcon } from "@heroicons/react/outline";
import useAuth from "../../hooks/useAuth";

export default function BatchPodscribeModal({
  show,
  totalEpisodes,
  totalMinutes,
  onClose,
  onConfirm,
  episodeDurations = [],
}) {
  const { auth } = useAuth();

  // Calculate available minutes
  const availableMinutes = auth.user.podscribe_minutes + auth.user.podscribe_topup_minutes;
  const hasEnoughCredits = availableMinutes >= totalMinutes;

  // Log for debugging
  useEffect(() => {
    if (show) {
      console.log("BatchPodscribeModal opened with:", {
        totalEpisodes,
        totalMinutes,
        availableMinutes,
        hasEnoughCredits,
        episodeDurations,
      });
    }
  }, [show, totalEpisodes, totalMinutes, availableMinutes, hasEnoughCredits, episodeDurations]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${
                      hasEnoughCredits ? "bg-green-100" : "bg-red-100"
                    }`}>
                    {hasEnoughCredits ? (
                      <LightningBoltIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    ) : (
                      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-neutral-900">
                      Process {totalEpisodes} Episode{totalEpisodes !== 1 ? "s" : ""}
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className="space-y-4">
                        <h4 className="font-semibold text-lg">Batch Processing Confirmation</h4>
                        <div className="space-y-2">
                          <p className="text-sm text-gray-600">
                            You're about to process multiple episodes. Here's what you need to know:
                          </p>
                          <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                            <li>
                              Total cost:{" "}
                              <span className="font-semibold text-gray-800">
                                {totalMinutes} minute{totalMinutes !== 1 ? "s" : ""}
                              </span>{" "}
                              (based on episode durations)
                            </li>
                            <li>
                              Your available balance:{" "}
                              <span className={`font-semibold ${hasEnoughCredits ? "text-green-600" : "text-red-600"}`}>
                                {availableMinutes} minute{availableMinutes !== 1 ? "s" : ""}
                              </span>
                            </li>
                            <li>Processing time varies based on episode lengths</li>
                            <li>Episodes will be processed in parallel where possible</li>
                          </ul>
                        </div>
                        {!hasEnoughCredits && (
                          <div className="bg-red-50 border-l-4 border-red-400 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                              </div>
                              <div className="ml-3">
                                <p className="text-sm text-red-700">
                                  You don't have enough credits to process these episodes. You need {totalMinutes}{" "}
                                  minute{totalMinutes !== 1 ? "s" : ""}, but you only have {availableMinutes} minute
                                  {availableMinutes !== 1 ? "s" : ""} available.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:col-start-2 ${
                      hasEnoughCredits
                        ? "bg-primary-600 hover:bg-primary-500 focus-visible:outline-primary-600"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    onClick={onConfirm}
                    disabled={!hasEnoughCredits}>
                    <LightningBoltIcon className="mr-2 h-5 w-5" />
                    {hasEnoughCredits ? "Process Episodes" : "Not Enough Credits"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 sm:col-start-1 sm:mt-0"
                    onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
