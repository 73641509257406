import React, { useState, useMemo, useContext } from 'react';
import { axiosPrivate } from "../../../common/axiosPrivate";
import { ClipLoader } from "react-spinners";
import { format } from 'date-fns';
import { CheckIcon, XIcon, RssIcon } from '@heroicons/react/solid';
import { ExclamationIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import LibraryDataContext from '../../../context/LibraryDataProvider';
import AuthContext from '../../../context/AuthProvider';

/**
 * RSS Feed Import tab component for importing podcast episodes from RSS feeds
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onClose - Function called when the modal should close
 */
function RSSImportTab({ onClose }) {
  const { refreshAfterRssImport } = useContext(LibraryDataContext);
  const { auth } = useContext(AuthContext);
  const [rssUrl, setRssUrl] = useState('');
  const [feedData, setFeedData] = useState(null);
  const [selectedEpisodes, setSelectedEpisodes] = useState([]);
  const [timeFilter, setTimeFilter] = useState('all'); // all, 30days, 60days, 90days
  const [isValidating, setIsValidating] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  /**
   * Validates the RSS feed URL and fetches feed data
   */
  const validateRss = async () => {
    if (!rssUrl) return;
    
    if (!auth.user || auth.user.subscription_status !== "active") {
      setError("You need an active subscription to use this feature.");
      return;
    }
    
    setIsValidating(true);
    setError(null);
    setFeedData(null);
    
    try {
      const formData = new FormData();
      formData.append('rss_url', rssUrl);
      
      const response = await axiosPrivate.post('/rss/validate', formData);
      setFeedData(response.data);
      
      // Select all episodes by default
      if (response.data.episodes.length > 0) {
        setSelectedEpisodes(response.data.episodes.map(ep => ep.id));
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to validate RSS feed');
    } finally {
      setIsValidating(false);
    }
  };
  
  /**
   * Imports the selected episodes to the user's library
   */
  const importEpisodes = async () => {
    if (selectedEpisodes.length === 0) return;
    
    if (!auth.user || auth.user.subscription_status !== "active") {
      setError("You need an active subscription to use this feature.");
      return;
    }
    
    setIsImporting(true);
    setError(null);
    setSuccess(null);
    
    try {
      const formData = new FormData();
      formData.append('rss_url', rssUrl);
      formData.append('episode_ids', JSON.stringify(selectedEpisodes));
      formData.append('time_filter', timeFilter);
      
      const response = await axiosPrivate.post('/rss/import', formData);
      setSuccess(response.data.message);
      
      // Update the frontend state with newly imported episodes
      if (response.data.imported_episodes && response.data.imported_episodes.length > 0) {
        await refreshAfterRssImport(response.data.imported_episodes);
      }
      
      // Auto-close after successful import
      setTimeout(() => {
        if (onClose) onClose();
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to import episodes');
    } finally {
      setIsImporting(false);
    }
  };
  
  /**
   * Filters episodes based on the selected time filter
   */
  const filteredEpisodes = useMemo(() => {
    if (!feedData?.episodes?.length) return [];
    
    if (timeFilter === 'all') return feedData.episodes;
    
    const now = new Date();
    const days = timeFilter === '30days' ? 30 : (timeFilter === '60days' ? 60 : 90);
    const cutoffTimestamp = Math.floor(now.setDate(now.getDate() - days) / 1000);
    
    return feedData.episodes.filter(episode => 
      episode.datePublished && episode.datePublished >= cutoffTimestamp
    );
  }, [feedData, timeFilter]);
  
  /**
   * Toggles selection of an episode
   * 
   * @param {string} episodeId - ID of the episode to toggle
   */
  const toggleEpisodeSelection = (episodeId) => {
    setSelectedEpisodes(prev => 
      prev.includes(episodeId) 
        ? prev.filter(id => id !== episodeId)
        : [...prev, episodeId]
    );
  };
  
  /**
   * Selects all currently filtered episodes
   */
  const selectAllEpisodes = () => {
    setSelectedEpisodes(filteredEpisodes.map(ep => ep.id));
  };
  
  /**
   * Deselects all episodes
   */
  const deselectAllEpisodes = () => {
    setSelectedEpisodes([]);
  };
  
  /**
   * Formats duration in seconds to a human-readable string
   * 
   * @param {number} seconds - Duration in seconds
   * @returns {string} Formatted duration string
   */
  const formatDuration = (seconds) => {
    if (!seconds) return '';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
    
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  
  return (
    <div className="space-y-6 h-full overflow-y-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        {(!auth.user || auth.user.subscription_status !== "active") && (
          <div className="bg-secondary-50 border border-secondary-200 rounded-lg p-4 mb-6">
            <p className="text-sm text-secondary-700 flex items-center">
              <ExclamationIcon className="h-5 w-5 text-secondary-500 mr-2" />
              You need an active subscription to use this feature. Please upgrade your account.
            </p>
          </div>
        )}
        
        <div className="mb-6">
          <label htmlFor="rss-url" className="block text-sm font-medium text-neutral-700 mb-1">
            RSS Feed URL
          </label>
          <div className="flex">
            <input
              type="url"
              id="rss-url"
              className="flex-1 shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-neutral-300 rounded-md"
              value={rssUrl}
              onChange={(e) => setRssUrl(e.target.value)}
              placeholder="https://example.com/feed.xml"
              disabled={isValidating || isImporting}
              aria-label="RSS feed URL"
            />
            <button
              type="button"
              onClick={validateRss}
              disabled={!rssUrl || isValidating || isImporting || !auth.user || auth.user.subscription_status !== "active"}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              aria-label="Validate RSS feed">
              {isValidating ? (
                <>
                  <ClipLoader className="w-4 h-4 mr-2" />
                  Validating...
                </>
              ) : (
                "Validate Feed"
              )}
            </button>
          </div>
          {error && (
            <div className="mt-2 flex items-center text-sm text-support-red-500">
              <XIcon className="h-5 w-5 mr-1 flex-shrink-0" aria-hidden="true" />
              {error}
            </div>
          )}
        </div>

        {isValidating && (
          <div className="bg-white shadow-sm rounded-lg overflow-hidden transition-all duration-300 mt-6 mb-8">
            <div className="p-8">
              <div className="flex flex-col items-center justify-center">
                <div className="mb-4">
                  <ClipLoader className="h-8 w-8 text-primary-600" />
                </div>
                <h3 className="text-lg font-medium text-neutral-700 text-center">Validating RSS feed...</h3>
                <p className="mt-2 text-sm text-neutral-500 text-center">Please wait while we analyze the feed</p>
              </div>
            </div>
          </div>
        )}

        {!isValidating && feedData && (
          <>
            <div className="mb-6 bg-white p-4 rounded-lg shadow-sm border border-neutral-200">
              <div className="flex items-center">
                {feedData.podcast.image ? (
                  <img
                    src={feedData.podcast.image}
                    alt={feedData.podcast.title}
                    className="h-16 w-16 rounded-md object-cover mr-4 flex-shrink-0"
                  />
                ) : (
                  <div className="h-16 w-16 rounded-md bg-primary-100 text-primary-600 flex items-center justify-center mr-4 flex-shrink-0">
                    <RssIcon className="h-8 w-8" aria-hidden="true" />
                  </div>
                )}
                <div>
                  <h3 className="text-lg font-medium text-neutral-800">{feedData.podcast.title}</h3>
                  <p className="text-sm text-neutral-500 line-clamp-2 mt-1">{feedData.podcast.description}</p>
                  {feedData.podcast.author && (
                    <p className="text-xs text-neutral-400 mt-1">by {feedData.podcast.author}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-6">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center space-x-3">
                  <h3 className="text-lg font-medium text-neutral-800">Episodes</h3>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-800">
                    {feedData.episodes.length}
                  </span>
                </div>

                <div className="flex items-center">
                  <label htmlFor="time-filter" className="text-sm text-neutral-500 mr-2">
                    Show:
                  </label>
                  <select
                    id="time-filter"
                    value={timeFilter}
                    onChange={(e) => setTimeFilter(e.target.value)}
                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 text-sm border-neutral-300 rounded-md"
                    aria-label="Time filter">
                    <option value="all">All episodes</option>
                    <option value="30days">Last 30 days</option>
                    <option value="60days">Last 60 days</option>
                    <option value="90days">Last 90 days</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-end mb-2 text-sm">
                <button
                  type="button"
                  onClick={selectAllEpisodes}
                  className="text-primary-600 hover:text-primary-800 mr-3 transition-colors duration-150">
                  Select All
                </button>
                <button
                  type="button"
                  onClick={deselectAllEpisodes}
                  className="text-primary-600 hover:text-primary-800 transition-colors duration-150">
                  Deselect All
                </button>
              </div>

              <div className="border border-neutral-200 rounded-lg overflow-hidden bg-white">
                {filteredEpisodes.length > 0 ? (
                  <ul className="divide-y divide-neutral-200 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                    {filteredEpisodes.map((episode) => (
                      <li key={episode.id} className="p-4 hover:bg-neutral-50 transition-colors duration-150">
                        <div className="flex items-start">
                          <div className="flex items-center h-5 mt-1">
                            <input
                              type="checkbox"
                              checked={selectedEpisodes.includes(episode.id)}
                              onChange={() => toggleEpisodeSelection(episode.id)}
                              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300 rounded"
                              aria-label={`Select ${episode.title}`}
                            />
                          </div>
                          <div className="ml-3 flex-1 min-w-0">
                            <p className="font-medium text-neutral-700 truncate">{episode.title}</p>
                            <div className="flex items-center text-sm text-neutral-500 mt-1">
                              {episode.datePublished && (
                                <span className="mr-2">
                                  {format(new Date(episode.datePublished * 1000), "MMM d, yyyy")}
                                </span>
                              )}
                              {episode.duration > 0 && <span>{formatDuration(episode.duration)}</span>}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="p-6 text-center">
                    <p className="text-neutral-500">No episodes found for the selected time period.</p>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={importEpisodes}
                disabled={selectedEpisodes.length === 0 || isImporting || !auth.user || auth.user.subscription_status !== "active"}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                aria-label="Import selected episodes">
                {isImporting ? (
                  <>
                    <ClipLoader className="w-4 h-4 mr-2" />
                    Importing...
                  </>
                ) : (
                  `Import ${selectedEpisodes.length} Episode${selectedEpisodes.length !== 1 ? "s" : ""}`
                )}
              </button>
            </div>

            {success && (
              <div className="mt-4 p-4 bg-support-green-50 text-support-green-800 rounded-md border border-support-green-100 flex items-center">
                <CheckIcon className="h-5 w-5 mr-2 flex-shrink-0" aria-hidden="true" />
                {success}
              </div>
            )}
          </>
        )}

        {!isValidating && !feedData && !error && (
          <div className="bg-white shadow-sm rounded-lg overflow-hidden transition-all duration-300 mt-6 mb-8">
            <div className="p-8">
              <div className="flex flex-col items-center justify-center">
                <RssIcon className="h-16 w-16 text-neutral-300 mb-4" aria-hidden="true" />
                <h3 className="text-lg font-medium text-neutral-700 text-center">Enter an RSS feed URL</h3>
                <p className="mt-2 text-sm text-neutral-500 text-center">
                  Paste the URL of a podcast RSS feed to import episodes
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

RSSImportTab.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default RSSImportTab;