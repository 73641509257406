import React, { useEffect, useReducer, useContext, useRef, useState } from "react";
import { axiosPrivate } from "../../../common/axiosPrivate";
import LoadingSpinner from "../../common/LoadingSpinner";
import Button from "../../../UI/Button";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/solid";
import PodcastProfileHeadingCard from "../../../UI/PodcastProfileHeadingCard";
import PodcastEpisodeCard from "../../../UI/PodcastEpisodeCard";
import LibraryDataContext from "../../../context/LibraryDataProvider";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-hot-toast";

const FETCH_EPISODES = "FETCH_EPISODES";
const CHANGE_EPISODES_PAGE = "CHANGE_EPISODES_PAGE";
const SELECT_EPISODE = "SELECT_EPISODE";
const TOGGLE_SELECT_ALL = "TOGGLE_SELECT_ALL";
const CLEAR_SELECTION = "CLEAR_SELECTION";
const BATCH_UPDATE_EPISODES = "BATCH_UPDATE_EPISODES";

const initialState = {
  episodes: [],
  episodesCurrentPage: 1,
  episodesTotalPages: 1,
  episodesItemsPerPage: 50,
  episodesTotalItems: 0,
  isLoading: false,
  selectedEpisodes: new Set(),
};

const episodesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_EPISODES:
      return {
        ...state,
        episodes: action.payload.episodes,
        episodesCurrentPage: action.payload.page,
        episodesTotalPages: action.payload.pages,
        episodesItemsPerPage: action.payload.size,
        episodesTotalItems: action.payload.total,
        isLoading: false,
        selectedEpisodes: new Set(), // Clear selection when new data is fetched
      };
    case CHANGE_EPISODES_PAGE:
      return {
        ...state,
        episodesCurrentPage: action.payload,
        isLoading: true,
        selectedEpisodes: new Set(), // Clear selection when changing page
      };
    case "UPDATE_EPISODE":
      return {
        ...state,
        episodes: state.episodes.map((episode) =>
          episode.id === action.payload.episodeId
            ? { ...episode, is_added_to_library: action.payload.isAdded }
            : episode
        ),
      };
    case SELECT_EPISODE: {
      const newSelectedEpisodes = new Set(state.selectedEpisodes);
      if (action.payload.selected) {
        newSelectedEpisodes.add(action.payload.episodeId);
      } else {
        newSelectedEpisodes.delete(action.payload.episodeId);
      }
      return {
        ...state,
        selectedEpisodes: newSelectedEpisodes,
      };
    }
    case "SET_SELECTED_EPISODES": {
      // This case handles setting a specific set of episodes as selected
      // Used for limiting selection for non-subscribed users
      return {
        ...state,
        selectedEpisodes: action.payload,
      };
    }
    case TOGGLE_SELECT_ALL: {
      // If all are currently selected, clear selection; otherwise select all
      const allSelected =
        state.episodes.length > 0 && state.episodes.every((episode) => state.selectedEpisodes.has(episode.id));

      if (allSelected) {
        return {
          ...state,
          selectedEpisodes: new Set(),
        };
      } else {
        const allEpisodeIds = state.episodes.map((episode) => episode.id);
        return {
          ...state,
          selectedEpisodes: new Set(allEpisodeIds),
        };
      }
    }
    case CLEAR_SELECTION:
      return {
        ...state,
        selectedEpisodes: new Set(),
      };
    case BATCH_UPDATE_EPISODES:
      return {
        ...state,
        episodes: state.episodes.map((episode) => {
          if (action.payload.episodeIds.includes(episode.id)) {
            return {
              ...episode,
              is_added_to_library: action.payload.isAdded,
            };
          }
          return episode;
        }),
        selectedEpisodes: new Set(), // Clear selection after batch operation
      };
    default:
      return state;
  }
};

export default function PodcastEpisodes({ podcast, onBack, handleFavoriteOnClick, onClose }) {
  const [state, dispatch] = useReducer(episodesReducer, initialState);
  const [isBatchProcessing, setIsBatchProcessing] = useState(false);
  const { addLibraryEpisode, removeLibraryEpisode, executeBatch, setLibraryData } = useContext(LibraryDataContext);
  const episodesContainerRef = useRef(null);
  const { auth } = useAuth();
  const isSubscribed = auth.user?.subscription_status === "active";
  const MAX_INBOX_ITEMS_FREE = 10;

  // Get the current number of episodes in the library to check against limit
  const { LibraryData } = useContext(LibraryDataContext);
  const currentInboxCount = LibraryData.episodes?.length || 0;
  const remainingEpisodes = isSubscribed ? Infinity : Math.max(0, MAX_INBOX_ITEMS_FREE - currentInboxCount);

  useEffect(() => {
    const fetchEpisodes = async () => {
      dispatch({ type: CHANGE_EPISODES_PAGE, payload: 1 });
      try {
        const response = await axiosPrivate.get(
          `/podcast/${podcast.id}/episodes?page=1&size=${state.episodesItemsPerPage}`
        );
        dispatch({
          type: FETCH_EPISODES,
          payload: {
            episodes: response.data.items,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          },
        });
        episodesContainerRef.current?.scrollIntoView();
      } catch (error) {
        console.error("Error fetching episodes: ", error);
      }
    };
    fetchEpisodes();
  }, [podcast.id, state.episodesItemsPerPage]);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= state.episodesTotalPages) {
      dispatch({ type: CHANGE_EPISODES_PAGE, payload: newPage });
      try {
        const response = await axiosPrivate.get(`/podcast/${podcast.id}/episodes`, {
          params: {
            page: newPage,
            size: state.episodesItemsPerPage,
          },
        });
        dispatch({
          type: FETCH_EPISODES,
          payload: {
            episodes: response.data.items,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          },
        });
        episodesContainerRef.current?.scrollIntoView();
      } catch (error) {
        console.error("Error fetching episodes: ", error);
      }
    }
  };

  const handleAddRemove = async (feedId, episodeId, isAdded) => {
    if (isAdded) {
      await addLibraryEpisode(feedId, episodeId);
    } else {
      await removeLibraryEpisode(feedId, episodeId);
    }
    dispatch({ type: "UPDATE_EPISODE", payload: { episodeId, isAdded } });
  };

  const handleSelectEpisode = (episodeId, selected) => {
    // If user is trying to select an episode
    if (selected) {
      // Check if user is not subscribed and would exceed the limit with this selection
      if (!isSubscribed && state.selectedEpisodes.size >= remainingEpisodes) {
        toast.error(
          `Free accounts are limited to ${MAX_INBOX_ITEMS_FREE} episodes in total. Please subscribe for unlimited episodes.`
        );
        return; // Don't allow selection
      }
    }

    // Proceed with selection/deselection
    dispatch({ type: SELECT_EPISODE, payload: { episodeId, selected } });
  };

  const handleSelectAll = () => {
    // If user is not subscribed, we need to limit selection
    if (!isSubscribed) {
      const allSelected =
        state.episodes.length > 0 && state.episodes.every((episode) => state.selectedEpisodes.has(episode.id));

      if (!allSelected) {
        // If selecting all and user is not subscribed, limit to what they can add
        if (remainingEpisodes <= 0) {
          toast.error(
            `Free accounts are limited to ${MAX_INBOX_ITEMS_FREE} episodes. Please subscribe for unlimited episodes.`
          );
          return;
        } else if (state.episodes.length > remainingEpisodes) {
          // Select only up to the remaining allowed episodes
          const episodesToSelect = state.episodes.slice(0, remainingEpisodes);
          const newSelectedEpisodes = new Set(episodesToSelect.map((ep) => ep.id));

          dispatch({
            type: "SET_SELECTED_EPISODES",
            payload: newSelectedEpisodes,
          });

          toast(`Selected ${remainingEpisodes} episodes (the maximum allowed for free accounts)`);
          return;
        }
      }
    }

    // Default behavior for subscribed users or when selection is within limits
    dispatch({ type: TOGGLE_SELECT_ALL });
  };

  const handleClearSelection = () => {
    dispatch({ type: CLEAR_SELECTION });
  };

  const handleBatchAdd = async () => {
    if (state.selectedEpisodes.size === 0) {
      toast.error("No episodes selected");
      return;
    }

    setIsBatchProcessing(true);
    try {
      const selectedEpisodeIds = Array.from(state.selectedEpisodes);
      const selectedEpisodes = state.episodes.filter((ep) => selectedEpisodeIds.includes(ep.id));

      // Prepare batch requests
      const batchRequests = selectedEpisodes.map((episode) => ({
        id: `add-episode-${episode.id}`,
        method: "POST",
        url: `/library/${podcast.id}/${episode.id}`,
      }));

      // Execute batch operation
      const result = await executeBatch(batchRequests);

      if (result.success) {
        // Update local state in the component
        dispatch({
          type: BATCH_UPDATE_EPISODES,
          payload: {
            episodeIds: selectedEpisodeIds,
            isAdded: true,
          },
        });

        // Force re-render the list by updating each episode's state individually
        selectedEpisodes.forEach((episode) => {
          // This ensures each card component gets the updated state
          episode.is_added_to_library = true;
        });

        // Update the LibraryDataContext to make episodes visible across the application
        // Process successful responses to get enriched episode data from the server
        const successfulResponses = result.responses
          .filter((response) => response.status >= 200 && response.status < 300)
          .map((response) => response.body);

        // If we have response data, use it to update the LibraryDataContext with complete episode data
        if (successfulResponses.length > 0) {
          // Add episodes to the LibraryDataContext
          setLibraryData((prevData) => {
            // Create new episodes with isNew flag to highlight them in the UI
            const newLibraryEpisodes = successfulResponses.map((episodeData) => ({
              ...episodeData,
              isNew: true,
            }));

            // Add the new episodes to the existing episodes in LibraryDataContext
            return {
              ...prevData,
              episodes: [...prevData.episodes, ...newLibraryEpisodes],
              episodeCount: prevData.episodeCount + newLibraryEpisodes.length,
            };
          });
        }

        toast.success(`Added ${selectedEpisodeIds.length} episodes to your library`);

        // Close the modal after successful batch operation if onClose is provided
        if (onClose && typeof onClose === "function") {
          onClose();
        }
      } else {
        toast.error("Failed to add episodes to library");
      }
    } catch (error) {
      console.error("Error in batch add operation:", error);
      toast.error("An error occurred while adding episodes");
    } finally {
      setIsBatchProcessing(false);
    }
  };

  const selectionCount = state.selectedEpisodes.size;
  const allSelected =
    state.episodes.length > 0 && state.episodes.every((episode) => state.selectedEpisodes.has(episode.id));

  return (
    <div ref={episodesContainerRef} className="h-full overflow-y-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Button onClick={onBack} className="mb-4">
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Back to Search Results
        </Button>
        <PodcastProfileHeadingCard feed={podcast} handleFavorite={handleFavoriteOnClick} />

        {/* User instructions for batch selection */}
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md my-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Save time by adding multiple episodes at once. Use the checkboxes to select episodes, then click "Add
                Selected" to add them all to your library.
              </p>
            </div>
          </div>
        </div>

        {/* Batch selection controls */}
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 bg-neutral-50 p-3 rounded-md border border-neutral-200 my-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="select-all"
              checked={allSelected}
              onChange={handleSelectAll}
              className="h-4 w-4 min-w-[1rem] rounded border-neutral-300 text-primary-600 focus:ring-primary-500"
            />
            <label htmlFor="select-all" className="text-sm font-medium text-neutral-700">
              {allSelected ? "Deselect All" : "Select All"}
            </label>
          </div>

          {!isSubscribed && (
            <div className="text-xs text-secondary-700 italic lg:text-sm mt-1 sm:mt-0">
              {remainingEpisodes > 0
                ? `Free accounts can add up to ${MAX_INBOX_ITEMS_FREE} episodes (${remainingEpisodes} remaining)`
                : "You've reached your limit of 10 episodes. Please subscribe for unlimited episodes."}
            </div>
          )}

          <div className="flex flex-wrap items-center gap-3">
            {selectionCount > 0 && (
              <>
                <span className="text-sm text-neutral-600">{selectionCount} selected</span>
                <button
                  onClick={handleClearSelection}
                  className="text-sm text-neutral-500 hover:text-neutral-700 px-2 py-1">
                  Clear
                </button>
                <button
                  onClick={handleBatchAdd}
                  disabled={
                    isBatchProcessing || selectionCount === 0 || (!isSubscribed && selectionCount > remainingEpisodes)
                  }
                  className="inline-flex items-center justify-center w-full sm:w-auto px-3 py-2 text-sm font-medium rounded-md 
                            bg-primary-600 text-white hover:bg-primary-700 focus:outline-none 
                            focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                            disabled:opacity-50 disabled:cursor-not-allowed">
                  {isBatchProcessing ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    <>Add Selected</>
                  )}
                </button>
              </>
            )}
          </div>
        </div>

        <h2 className="text-xl font-bold text-left text-primary-600 mt-4">Episodes</h2>
        {state.isLoading && state.episodes.length === 0 ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="grid gap-6 mt-4">
              {state.episodes.map((episode, index) => (
                <div key={index} className="w-full">
                  <PodcastEpisodeCard
                    episode={episode}
                    handleAddRemove={handleAddRemove}
                    onCloseModal={onClose}
                    isSelectable={true}
                    isSelected={state.selectedEpisodes.has(episode.id)}
                    onSelectChange={(episodeId, selected) => handleSelectEpisode(episodeId, selected)}
                  />
                </div>
              ))}
            </div>
            <nav
              aria-label="Pagination"
              className="flex flex-col sm:flex-row items-center justify-between border-t border-neutral-100 bg-white px-4 py-3 sm:px-6 mt-6 sticky bottom-0 shadow-sm">
              <div className="text-sm text-neutral-500 text-center sm:text-left">
                <p className="sm:inline hidden">
                  Showing{" "}
                  <span className="font-medium">
                    {(state.episodesCurrentPage - 1) * state.episodesItemsPerPage + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(state.episodesCurrentPage * state.episodesItemsPerPage, state.episodesTotalItems)}
                  </span>{" "}
                  of <span className="font-medium">{state.episodesTotalItems}</span> results
                </p>
                {/* Mobile pagination info */}
                <p className="sm:hidden">
                  Page <span className="font-medium">{state.episodesCurrentPage}</span> of{" "}
                  <span className="font-medium">{state.episodesTotalPages}</span>
                </p>
              </div>
              <div className="flex justify-between w-full sm:w-auto gap-2 mt-3 sm:mt-0">
                <button
                  onClick={() => handlePageChange(state.episodesCurrentPage - 1)}
                  disabled={state.isLoading || state.episodesCurrentPage === 1}
                  className="relative inline-flex items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-neutral-700 border border-neutral-200
                  hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 min-w-[90px]">
                  Previous
                </button>
                <button
                  onClick={() => handlePageChange(state.episodesCurrentPage + 1)}
                  disabled={state.isLoading || state.episodesCurrentPage === state.episodesTotalPages}
                  className="relative inline-flex items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-neutral-700 border border-neutral-200
                  hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 min-w-[90px]">
                  Next
                </button>
              </div>
            </nav>
          </>
        )}
      </div>
    </div>
  );
}
