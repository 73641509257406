import react, { useEffect, useCallback, useState, useRef, useReducer } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { TrashIcon as TrashOutlineIcon } from "@heroicons/react/outline";
import BatchFolderModal from "./BatchFolderModal";
import BatchPodscribeModal from "./BatchPodscribeModal";
import MultiOperationHeader from "./MultiOperationHeader";
import SearchHeader from "./SearchHeader";
import { calculateProcessingTime, getRemainingTime } from "../../utils/processingTime";
import useLibraryData from "../../hooks/useLibraryData";
import AddInboxContentModal from "./add_inbox/AddInboxContentModal";
import classNames from "classnames";
import { Dialog, Transition, Menu as HeadlessMenu } from "@headlessui/react";
import Img from "../../UI/Img";
import AddFolderModal from "./AddFolderModal";
import { remove_html_tags } from "../../common/utils";
import { format } from "date-fns";
import MobileInbox from "./MobileInbox";
import * as OutlineIcons from "@heroicons/react/outline";
import * as SolidIcons from "@heroicons/react/solid";
import { DotsVerticalIcon, FolderIcon } from "@heroicons/react/solid";
import {
  SortAscendingIcon,
  SortDescendingIcon,
  ViewListIcon,
  ViewGridIcon,
  GiftIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { PlusIcon as PlusIconOutline, TrashIcon } from "@heroicons/react/outline";
import useUIState from "../../hooks/useUIState";
import SearchInput from "../../UI/SearchComponent";
import { useDebounceValue } from "usehooks-ts";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import DeleteFolderModal from "./DeleteFolderModal";
import BatchExportModal from "./BatchExportModal";
import { axiosPrivate } from "../../common/axiosPrivate";

function Highlight({ text = "", highlight = "", className = "" }) {
  if (!highlight.trim()) {
    return <span className={className}>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);
  return (
    <span className={className}>
      {parts.filter((part) => part).map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : part))}
    </span>
  );
}

const getInitialInboxState = (UIState) => ({
  episode_list: [],
  filteredEpisodeList: [], // the Actual list of Episodes to show in the Inbox
  selectedEpisode: null, // the currently selected Episode
  folders: [], // Folders and their episodes.
  currentFolder: null, // Track current folder
  sortConfig: UIState.inboxConfig.sort,
  isGrouped: UIState.inboxConfig.group.isGrouped,
  groupKey: UIState.inboxConfig.group.groupKey,
});

function sortEpisodes(episode_list, sortKey, sortOrder) {
  const sortedList = [...episode_list];

  const sortFunctions = {
    datePublished: (a, b) => a.datePublished - b.datePublished,
    title: (a, b) => a.title.localeCompare(b.title),
    podcast: (a, b) => a.podcast.localeCompare(b.podcast),
    podcast_title: (a, b) => a.podcast_title.localeCompare(b.podcast_title),
  };

  const sortFn = sortFunctions[sortKey] || sortFunctions.datePublished;

  return sortedList.sort((a, b) => (sortOrder === "asc" ? sortFn(a, b) : sortFn(b, a)));
}

function groupEpisodes(episode_list, groupKey) {
  const groupedList = [...episode_list];

  if (!["podcast", "podcast_title"].includes(groupKey)) {
    return groupedList;
  }

  // Group episodes by the specified key
  const groups = groupedList.reduce((acc, episode) => {
    const key = episode[groupKey];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(episode);
    return acc;
  }, {});

  // Flatten groups back into array, maintaining group order
  return Object.entries(groups)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .flatMap(([_, episodes]) => episodes);
}

function filterByFolder(episodes, folder) {
  if (!folder || folder.id === "all") {
    return episodes;
  } else if (folder.id === "starred") {
    return episodes.filter((ep) => ep.is_starred);
  } else {
    return episodes.filter((episode) => folder.episodes?.includes(episode.id));
  }
}

function searchEpisodes(episode_list, searchTerm) {
  if (searchTerm === "") {
    return episode_list;
  } else {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return episode_list.filter((episode) => {
      return (
        episode.title.toLowerCase().includes(lowerCaseSearchTerm) ||
        // episode.description.toLowerCase().includes(lowerCaseSearchTerm) ||
        episode.podcast_title.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
  }
}

function inboxReducer(state, action) {
  switch (action.type) {
    case "INIT": {
      const initialFilteredList = action.currentFolder
        ? filterByFolder(action.episode_list, action.currentFolder)
        : action.episode_list;

      // Apply sorting using the UIProvider settings
      const sortedInitialList = sortEpisodes(initialFilteredList, state.sortConfig.key, state.sortConfig.order);

      // Apply grouping using the UIProvider settings
      const finalList = state.isGrouped ? groupEpisodes(sortedInitialList, state.groupKey) : sortedInitialList;

      return {
        ...state,
        episode_list: action.episode_list,
        filteredEpisodeList: finalList,
        selectedEpisode: action.selectedEpisode,
        folders: action.folders || [],
        currentFolder: action.currentFolder || null,
      };
    }

    case "SET_SORT_CONFIG":
      const sortedList = sortEpisodes([...state.filteredEpisodeList], action.sortKey, action.sortOrder);
      return {
        ...state,
        sortConfig: {
          key: action.sortKey,
          order: action.sortOrder,
        },
        filteredEpisodeList: state.isGrouped ? groupEpisodes(sortedList, state.groupKey) : sortedList,
      };

    case "SET_GROUP_CONFIG":
      return {
        ...state,
        isGrouped: action.isGrouped,
        groupKey: action.groupKey,
        filteredEpisodeList: action.isGrouped
          ? groupEpisodes(
              sortEpisodes([...state.filteredEpisodeList], state.sortConfig.key, state.sortConfig.order),
              action.groupKey
            )
          : sortEpisodes([...state.filteredEpisodeList], state.sortConfig.key, state.sortConfig.order),
      };

    case "SET_CURRENT_FOLDER": {
      let newFilteredList;
      if (!action.folder || action.folder.id === "all") {
        newFilteredList = state.episode_list;
      } else if (action.folder.id === "starred") {
        newFilteredList = state.episode_list.filter((ep) => ep.is_starred);
      } else {
        newFilteredList = state.episode_list.filter((episode) => action.folder.episodes?.includes(episode.id));
      }

      // Apply sorting
      const sortedList = sortEpisodes(newFilteredList, state.sortConfig.key, state.sortConfig.order);

      // Apply grouping if enabled
      const finalList = state.isGrouped ? groupEpisodes(sortedList, state.groupKey) : sortedList;

      return {
        ...state,
        currentFolder: action.folder,
        filteredEpisodeList: finalList,
      };
    }

    case "SEARCH_EPISODES": {
      // First get the base list according to current folder
      const baseList = state.currentFolder
        ? state.currentFolder.id === "all"
          ? state.episode_list
          : state.currentFolder.id === "starred"
          ? state.episode_list.filter((ep) => ep.is_starred)
          : state.episode_list.filter((ep) => state.currentFolder.episodes?.includes(ep.id))
        : state.episode_list;

      // Then apply search on that filtered list
      const searchedList = searchEpisodes(baseList, action.searchTerm);

      // Apply sorting
      const sortedList = sortEpisodes(searchedList, state.sortConfig.key, state.sortConfig.order);

      // Apply grouping if enabled
      const finalList = state.isGrouped ? groupEpisodes(sortedList, state.groupKey) : sortedList;

      return {
        ...state,
        filteredEpisodeList: finalList,
      };
    }

    case "SORT_EPISODES":
      return {
        ...state,
        filteredEpisodeList: sortEpisodes([...state.filteredEpisodeList], action.sortKey, action.sortOrder),
      };

    case "GROUP_EPISODES":
      return {
        ...state,
        filteredEpisodeList: groupEpisodes([...state.filteredEpisodeList], action.groupKey),
      };

    case "UNGROUP_EPISODES":
      // Maintain current folder's episodes when ungrouping
      const currentList = state.currentFolder
        ? state.currentFolder.id === "all"
          ? state.episode_list
          : state.currentFolder.id === "starred"
          ? state.episode_list.filter((ep) => ep.is_starred)
          : state.episode_list.filter((ep) => state.currentFolder.episodes?.includes(ep.id))
        : state.episode_list;

      return {
        ...state,
        filteredEpisodeList: currentList,
      };

    case "UPDATE_FOLDERS": {
      // When folders update, maintain the current folder's filtered list
      let updatedFilteredList;
      if (state.currentFolder) {
        if (state.currentFolder.id === "all") {
          updatedFilteredList = state.episode_list;
        } else if (state.currentFolder.id === "starred") {
          updatedFilteredList = state.episode_list.filter((ep) => ep.is_starred);
        } else {
          const updatedFolder = action.folders.find((f) => f.id === state.currentFolder.id);
          if (updatedFolder) {
            updatedFilteredList = state.episode_list.filter((episode) => updatedFolder.episodes?.includes(episode.id));
          }
        }
      } else {
        updatedFilteredList = state.episode_list;
      }

      // Apply sorting
      const sortedList = sortEpisodes(updatedFilteredList, state.sortConfig.key, state.sortConfig.order);

      // Apply grouping if enabled
      const finalList = state.isGrouped ? groupEpisodes(sortedList, state.groupKey) : sortedList;

      return {
        ...state,
        folders: action.folders,
        filteredEpisodeList: finalList,
      };
    }

    case "SELECT_EPISODE":
      return {
        ...state,
        selectedEpisode: action.selectedEpisode,
      };

    default:
      return state;
  }
}

const getIconComponent = (iconName, className = "h-4 w-4") => {
  // Try to get the icon from both collections
  const OutlineComponent = OutlineIcons[iconName];
  const SolidComponent = SolidIcons[iconName];
  const IconComponent = OutlineComponent || SolidComponent;

  // Return the icon component if found, otherwise return a default folder icon
  if (IconComponent) {
    return <IconComponent className={className} />;
  }
  return <OutlineIcons.FolderIcon className={className} />;
};

export default function Inbox(props) {
  const { UIState, setUIState } = useUIState();
  const [inboxState, dispatchInboxState] = useReducer(inboxReducer, getInitialInboxState(UIState));
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const [isAddInboxContentModalOpen, setIsAddInboxContentModalOpen] = useState(false);
  const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [showFolderList, setShowFolderList] = useState(false);
  const [isBatchFolderModalOpen, setIsBatchFolderModalOpen] = useState(false);
  const [batchPodscribeModal, setBatchPodscribeModal] = useState({ open: false, episodeIds: [], totalMinutes: 0 });
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [multiSelectMode, setMultiSelectMode] = useState(false);
  const [selectedEpisodes, setSelectedEpisodes] = useState({});
  const [lastSelectedEpisodeId, setLastSelectedEpisodeId] = useState(null);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const { LibraryData, toggleStarEpisode, setLibraryData, removeMultipleEpisodes, executeBatch, batchExportEpisodes } = useLibraryData();
  const [exportProcessing, setExportProcessing] = useState(false);

  const { createFolder, updateFolder, deleteFolder, addEpisodeToFolder, removeEpisodeFromFolder } = useLibraryData();

  const handleAddEpisodeToFolder = useCallback(
    async (folderId, episodeId) => {
      try {
        await addEpisodeToFolder(folderId, episodeId);
        toast.success("Episode added to folder");
      } catch (error) {
        console.error("Error adding episode to folder:", error);
        toast.error("Failed to add episode to folder");
      }
    },
    [addEpisodeToFolder]
  );

  const handleCreateFolder = useCallback(
    async (folderData) => {
      try {
        await createFolder(folderData);
        toast.success("Folder created successfully");
      } catch (error) {
        console.error("Error creating folder:", error);
        toast.error("Failed to create folder");
      }
    },
    [createFolder]
  );

  const handleUpdateFolder = useCallback(
    async (folderId, folderData) => {
      try {
        await updateFolder(folderId, folderData);
        toast.success("Folder updated successfully");
      } catch (error) {
        console.error("Error updating folder:", error);
        toast.error("Failed to update folder");
      }
    },
    [updateFolder]
  );

  const handleDeleteFolder = useCallback(
    async (folderId) => {
      try {
        await deleteFolder(folderId);
        if (inboxState.currentFolder?.id === folderId) {
          dispatchInboxState({
            type: "SET_CURRENT_FOLDER",
            folder: null,
          });
        }
        toast.success("Folder deleted successfully");
      } catch (error) {
        console.error("Error deleting folder:", error);
        toast.error("Failed to delete folder");
      }
    },
    [deleteFolder, inboxState.currentFolder]
  );

  const handleRemoveEpisodeFromFolder = useCallback(
    async (folderId, episodeId) => {
      try {
        await removeEpisodeFromFolder(folderId, episodeId);
        toast.success("Episode removed from folder");
      } catch (error) {
        console.error("Error removing episode from folder:", error);
        toast.error("Failed to remove episode from folder");
      }
    },
    [removeEpisodeFromFolder]
  );

  const handleEpisodeSelection = (episode, isSelected, isShiftKey = false) => {
    if (isShiftKey && lastSelectedEpisodeId) {
      const visibleEpisodeIds = inboxState.filteredEpisodeList.map((ep) => ep.id);
      const lastSelectedIndex = visibleEpisodeIds.indexOf(lastSelectedEpisodeId);
      const currentIndex = visibleEpisodeIds.indexOf(episode.id);

      if (lastSelectedIndex !== -1 && currentIndex !== -1) {
        const startIndex = Math.min(lastSelectedIndex, currentIndex);
        const endIndex = Math.max(lastSelectedIndex, currentIndex);

        const newSelectedEpisodes = { ...selectedEpisodes };
        for (let i = startIndex; i <= endIndex; i++) {
          const id = visibleEpisodeIds[i];
          newSelectedEpisodes[id] = true;
        }
        setSelectedEpisodes(newSelectedEpisodes);
      }
    } else {
      setSelectedEpisodes((prev) => ({
        ...prev,
        [episode.id]: isSelected,
      }));
      setLastSelectedEpisodeId(episode.id);
    }
  };

  const handleSelectAll = (selected) => {
    if (selected) {
      const allSelected = {};
      inboxState.filteredEpisodeList.forEach((episode) => {
        allSelected[episode.id] = true;
      });
      setSelectedEpisodes(allSelected);
    } else {
      setSelectedEpisodes({});
    }
  };

  const handleClearSelection = () => {
    setSelectedEpisodes({});
    setLastSelectedEpisodeId(null);
  };

  const selectedCount = Object.keys(selectedEpisodes).filter((key) => selectedEpisodes[key]).length;
  const totalCount = inboxState.filteredEpisodeList.length;

  const handleBatchDelete = useCallback(async () => {
    console.log("handleBatchDelete called", selectedEpisodes);

    const episodeIds = Object.keys(selectedEpisodes).filter((id) => selectedEpisodes[id]);
    if (episodeIds.length === 0) return;

    const toastId = toast.loading(`Deleting ${episodeIds.length} episodes...`);
    try {
      await removeMultipleEpisodes(episodeIds);
      toast.success(`Successfully deleted ${episodeIds.length} episodes`, { id: toastId });
      handleClearSelection();
    } catch (error) {
      toast.error("Failed to delete some episodes", { id: toastId });
    }
  }, [selectedEpisodes, removeMultipleEpisodes]);

  const handleBatchAddToFolder = useCallback(() => {
    const episodeIds = Object.keys(selectedEpisodes).filter((id) => selectedEpisodes[id]);
    if (episodeIds.length === 0) {
      toast.error("No episodes selected");
      return;
    }
    setIsBatchFolderModalOpen(true);
  }, [selectedEpisodes]);

  const handleBatchExport = useCallback(() => {
    const selectedEpisodeIds = Object.keys(selectedEpisodes).filter((id) => selectedEpisodes[id]);
    if (selectedEpisodeIds.length === 0) {
      toast.error("No episodes selected for export");
      return;
    }
    setIsExportModalOpen(true);
  }, [selectedEpisodes]);

  const executeExport = async () => {
    try {
      const selectedEpisodeIds = Object.keys(selectedEpisodes).filter((id) => selectedEpisodes[id]);
      setExportProcessing(true);
      
      const result = await batchExportEpisodes(selectedEpisodeIds);
      
      if (result.success) {
        toast.success(`Successfully exported ${selectedEpisodeIds.length} episodes`);
        handleClearSelection();
      } else {
        toast.error(result.error || "Failed to export episodes");
      }
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Failed to export episodes. Please try again.");
    } finally {
      setIsExportModalOpen(false);
      setExportProcessing(false);
    }
  };

  const handleBatchPodscribe = useCallback(async () => {
    const episodeIds = Object.keys(selectedEpisodes).filter((id) => selectedEpisodes[id]);
    if (episodeIds.length === 0) {
      toast.error("No episodes selected");
      return;
    }

    // Calculate total minutes required with better error handling and detailed logging
    let episodeDurations = [];
    const totalMinutes = episodeIds.reduce((acc, id) => {
      // Convert ID to string to ensure consistent type comparison
      const episode = LibraryData.episodes.find((ep) => String(ep.id) === String(id));
      if (!episode) {
        console.warn(`Episode with id ${id} not found in LibraryData`);
        return acc + 60; // Default to 60 minutes if episode not found
      }

      // Get episode duration in seconds, with fallback
      const durationInSeconds = episode.duration || 3600;
      // Convert to minutes and round up
      const durationInMinutes = Math.ceil(durationInSeconds / 60);

      // Add to our debug tracking array
      episodeDurations.push({
        id,
        title: episode.title,
        durationInSeconds,
        durationInMinutes,
      });

      return acc + durationInMinutes;
    }, 0);

    console.log(`Batch Podscribe: ${episodeIds.length} episodes, ${totalMinutes} minutes total`);
    console.log("Episode durations:", episodeDurations);

    // Show the confirmation modal with calculated values
    setBatchPodscribeModal({
      open: true,
      episodeIds,
      totalMinutes,
      episodeDurations, // Adding this for debugging purposes
    });
  }, [selectedEpisodes, LibraryData]);

  const handleFolderClick = useCallback((folder) => {
    dispatchInboxState({
      type: "SET_CURRENT_FOLDER",
      folder: folder,
    });
    setShowFolderList(false);
    console.log("Folder clicked:", folder);
  }, []);

  const handleInboxSidebar = () => {
    setUIState({
      ...UIState,
      inboxSidebarOpen: !UIState.inboxSidebarOpen,
    });
  };

  // init the inbox data
  useEffect(() => {
    dispatchInboxState({
      type: "INIT",
      episode_list: props.episodes,
      selectedEpisode: props.selectedEpisode,
      folders: LibraryData.folders,
      currentFolder: inboxState.currentFolder,
    });
  }, [props.episodes, props.selectedEpisode, LibraryData.folders]);

  // Effect to update folders when LibraryData.folders changes
  useEffect(() => {
    dispatchInboxState({
      type: "UPDATE_FOLDERS",
      folders: LibraryData.folders,
    });
  }, [LibraryData.folders]);

  // handle the click on the episode
  const handleEpisodeClick = (episode) => {
    if (episode.isNew) {
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.map((ep) => (ep.id === episode.id ? { ...ep, isNew: false } : ep)),
      }));
    }
    // Reset to "All Episodes" folder if the episode isn't in the current folder
    if (!inboxState.filteredEpisodeList.some((ep) => ep.id === episode.id)) {
      dispatchInboxState({
        type: "SET_CURRENT_FOLDER",
        folder: { id: "all", name: "All Episodes" },
      });
    }
    dispatchInboxState({ type: "SELECT_EPISODE", selectedEpisode: episode });
    // if the sidebar is open, close it
    if (UIState.inboxSidebarOpen) {
      handleInboxSidebar();
    }
    props.SelectEpisodeHandler(episode.id);
  };

  // Expose the filtered episode list to parent
  useEffect(() => {
    if (props.onFilteredListChange) {
      props.onFilteredListChange(inboxState.filteredEpisodeList);
    }
  }, [inboxState.filteredEpisodeList, props.onFilteredListChange]);

  const handleSort = () => {
    const newOrder = inboxState.sortConfig.order === "asc" ? "desc" : "asc";
    const newSort = {
      key: inboxState.sortConfig.key,
      order: newOrder,
    };

    // Update UIProvider state
    setUIState({
      ...UIState,
      inboxConfig: {
        ...UIState.inboxConfig,
        sort: newSort,
      },
    });

    // Update local state
    dispatchInboxState({
      type: "SET_SORT_CONFIG",
      sortKey: newSort.key,
      sortOrder: newSort.order,
    });
  };

  const handleGroup = () => {
    const newGroup = {
      isGrouped: !inboxState.isGrouped,
      groupKey: "podcast_title",
    };

    // Update UIProvider state
    setUIState({
      ...UIState,
      inboxConfig: {
        ...UIState.inboxConfig,
        group: newGroup,
      },
    });

    // Update local state
    dispatchInboxState({
      type: "SET_GROUP_CONFIG",
      isGrouped: newGroup.isGrouped,
      groupKey: newGroup.groupKey,
    });
  };

  // useEffect to set the selected episode class
  useEffect(() => {
    if (props.selectedEpisodeState) {
      dispatchInboxState({
        type: "SELECT_EPISODE",
        selectedEpisode: props.selectedEpisodeState.episode,
      });
    }
  }, [props.selectedEpisodeState]);

  const handleSearch = (e) => {
    setDebouncedSearchValue(e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleOpenAddInboxContentModal = () => {
    setIsAddInboxContentModalOpen(true);
  };

  // handle the search
  useEffect(() => {
    dispatchInboxState({ type: "SEARCH_EPISODES", searchTerm: debouncedSearchValue });
  }, [debouncedSearchValue]);

  return (
    <>
      <MobileInbox
        isEmpty={props.isEmpty}
        multiSelectMode={multiSelectMode}
        selectedCount={selectedCount}
        totalCount={totalCount}
        onSelectAll={handleSelectAll}
        onClearSelection={handleClearSelection}
        onDelete={handleBatchDelete}
        onAddToFolder={handleBatchAddToFolder}
        onExport={handleBatchExport}
        onPodscribe={handleBatchPodscribe}>
        <div className="flex-shrink-0">
          {/* Search and Sort Controls */}
          <SearchHeader
            searchTerm={searchTerm}
            onSearch={(e) => handleSearch(e)}
            showFolderList={showFolderList}
            onFolderListToggle={() => setShowFolderList(!showFolderList)}
            onSort={handleSort}
            onGroup={handleGroup}
            sortConfig={inboxState.sortConfig}
            isGrouped={inboxState.isGrouped}
            multiSelectMode={multiSelectMode}
            onToggleMultiSelect={() => {
              setMultiSelectMode(!multiSelectMode);
              if (multiSelectMode) {
                handleClearSelection();
              }
            }}
          />
          {multiSelectMode && (
            <MultiOperationHeader
              selectedCount={selectedCount}
              totalCount={totalCount}
              onSelectAll={handleSelectAll}
              onClearSelection={handleClearSelection}
              onDelete={handleBatchDelete}
              onAddToFolder={handleBatchAddToFolder}
              onExport={handleBatchExport}
              onPodscribe={handleBatchPodscribe}
            />
          )}
          {/* Folder List */}
          {showFolderList && (
            <div className="px-6 py-4 border-t border-neutral-200">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-sm font-medium text-neutral-900">Folders</h3>
                <button
                  onClick={() => setIsAddFolderModalOpen(true)}
                  className="inline-flex items-center p-1 border border-transparent rounded-md text-secondary-600 hover:text-secondary-700"
                  title="New Folder">
                  <PlusIconOutline className="h-5 w-5" />
                </button>
              </div>
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={() => handleFolderClick({ id: "all", name: "All Episodes" })}
                    className={classNames(
                      "w-full text-left px-2 py-1 rounded-md text-sm flex items-center space-x-2",
                      inboxState.currentFolder?.id === "all"
                        ? "bg-primary-50 text-primary-700"
                        : "text-neutral-700 hover:bg-neutral-50"
                    )}>
                    {getIconComponent("InboxIcon")}
                    <span>All Episodes</span>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleFolderClick({ id: "starred", name: "Starred" })}
                    className={classNames(
                      "w-full text-left px-2 py-1 rounded-md text-sm flex items-center space-x-2",
                      inboxState.currentFolder?.id === "starred"
                        ? "bg-primary-50 text-primary-700"
                        : "text-neutral-700 hover:bg-neutral-50"
                    )}>
                    {getIconComponent("StarIcon")}
                    <span>Starred</span>
                    <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-neutral-200 text-primary-800">
                      {LibraryData.episodes.filter((ep) => ep.is_starred).length}
                    </span>
                  </button>
                </li>
                {LibraryData.folders.map((folder) => (
                  <li key={folder.id}>
                    <div className="flex items-center justify-between group">
                      <button
                        onClick={() => handleFolderClick(folder)}
                        className={classNames(
                          "flex-grow text-left px-2 py-1 rounded-md text-sm flex items-center space-x-2",
                          inboxState.currentFolder?.id === folder.id
                            ? "bg-primary-50 text-primary-700"
                            : "text-neutral-700 hover:bg-neutral-50"
                        )}>
                        {getIconComponent(folder.icon || "FolderIcon")}
                        <span>{folder.name}</span>
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-neutral-200 text-primary-800">
                          {folder.episodes?.length || 0}
                        </span>
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setFolderToDelete(folder);
                          setIsDeleteFolderModalOpen(true);
                        }}
                        className="p-1 rounded-md text-neutral-400 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity">
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <nav
            aria-label="episode list"
            className="flex-1 overflow-y-auto p-1 mb-1 scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300"
            style={{ height: "calc(100vh - 180px)" }}>
            <ul className="divide-y">
              {inboxState.filteredEpisodeList.length === 0 ? (
                <li className="py-4 px-4 text-center">
                  <p className="text-gray-500 mb-4">
                    {searchTerm ? "No episodes match your search." : "No episodes in this folder."}
                  </p>
                  {!searchTerm && (
                    <button
                      onClick={() => setIsAddInboxContentModalOpen(true)}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500">
                      <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      Add Content
                    </button>
                  )}
                </li>
              ) : (
                inboxState.filteredEpisodeList.map((episode) => (
                  <li
                    key={episode.id}
                    onClick={() => handleEpisodeClick(episode)}
                    className={classNames(
                      "relative py-5 px-3 cursor-pointer transition-colors duration-150 ease-in-out",
                      episode.id === inboxState.selectedEpisode?.id
                        ? "bg-primary-50 border-l-4 border-l-primary-600 hover:bg-primary-100"
                        : episode.isNew
                        ? "bg-white border-l-4 border-l-secondary-600 hover:bg-neutral-50"
                        : "bg-white border-l-4 border-transparent hover:bg-neutral-50 hover:border-l-4 hover:border-l-primary-200"
                    )}>
                    <div className="min-w-0 flex-1 flex flex-row space-x-3">
                      {multiSelectMode && (
                        <div
                          className="flex items-center mr-1"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          <input
                            type="checkbox"
                            checked={!!selectedEpisodes[episode.id]}
                            onChange={(e) => {
                              const isShiftKey = e.nativeEvent.shiftKey;
                              handleEpisodeSelection(episode, e.target.checked, isShiftKey);
                            }}
                            className="h-4 w-4 text-primary-600 focus:ring-primary-500 rounded border-neutral-300"
                          />
                        </div>
                      )}
                      <Img
                        src={episode.feedImage}
                        alt=""
                        className="w-10 h-10 rounded-lg object-cover text-grey-600 flex-shrink-0"
                      />

                      <div className="flex-1 min-w-0 space-y-1">
                        {/* Title Row */}
                        <div className="w-full">
                          {searchTerm ? (
                            <Highlight
                              text={episode.title}
                              highlight={searchTerm}
                              className="text-sm font-semibold text-primary-700 line-clamp-1"
                            />
                          ) : (
                            <p className="text-sm font-semibold text-primary-700 line-clamp-1">{episode.title}</p>
                          )}
                        </div>

                        {/* Podcast Title and Date Row */}
                        <div className="flex justify-between items-center">
                          <div className="min-w-0 flex-1">
                            {searchTerm ? (
                              <Highlight
                                text={episode.podcast_title}
                                highlight={searchTerm}
                                className="text-xs text-secondary-700 line-clamp-1"
                              />
                            ) : (
                              <p className="text-xs text-secondary-700 line-clamp-1">{episode.podcast_title}</p>
                            )}
                          </div>
                          <time
                            dateTime={new Date(episode.datePublished * 1000).toISOString()}
                            className="ml-2 flex-shrink-0 whitespace-nowrap text-xs text-neutral-500">
                            {format(new Date(episode.datePublished * 1000), "MM/dd/yyyy")}
                          </time>
                        </div>

                        {/* Description Row */}
                        <p className="text-xs text-neutral-600 line-clamp-2">{remove_html_tags(episode.description)}</p>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </nav>
        </div>
      </MobileInbox>
      <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first bg-white ml-3 mt-3 rounded-lg h-full ">
        <div className="relative flex flex-col w-96 h-full">
          <div className="flex flex-col h-full">
            <div className="relative h-16 px-6 flex flex-col justify-center">
              <div className="flex items-baseline space-x-3">
                <h2 className="text-lg font-medium text-neutral-800">Inbox</h2>
                <p className="text-sm font-medium text-neutral-800">
                  {inboxState.episode_list.length} episodes
                  {LibraryData.isBackgroundLoading && (
                    <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-800">
                      <svg className="animate-spin -ml-1 mr-2 h-3 w-3 text-primary-600" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Loading more...
                    </span>
                  )}
                </p>
              </div>
            </div>

            <SearchHeader
              searchTerm={searchTerm}
              onSearch={(e) => handleSearch(e)}
              showFolderList={showFolderList}
              onFolderListToggle={() => setShowFolderList(!showFolderList)}
              onSort={handleSort}
              onGroup={handleGroup}
              sortConfig={inboxState.sortConfig}
              isGrouped={inboxState.isGrouped}
              multiSelectMode={multiSelectMode}
              onToggleMultiSelect={() => {
                setMultiSelectMode(!multiSelectMode);
                if (multiSelectMode) {
                  handleClearSelection();
                }
              }}
            />
            {multiSelectMode && (
              <MultiOperationHeader
                selectedCount={selectedCount}
                totalCount={totalCount}
                onSelectAll={handleSelectAll}
                onClearSelection={handleClearSelection}
                onDelete={handleBatchDelete}
                onAddToFolder={handleBatchAddToFolder}
                onExport={handleBatchExport}
                onPodscribe={handleBatchPodscribe}
              />
            )}
            {/* Folder List */}
            {showFolderList && (
              <div className="px-6 py-4 border-t border-neutral-200">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-sm font-medium text-neutral-900">Folders</h3>
                  <button
                    onClick={() => setIsAddFolderModalOpen(true)}
                    className="inline-flex items-center p-1 border border-transparent rounded-md text-secondary-600 hover:text-secondary-700"
                    title="New Folder">
                    <PlusIconOutline className="h-5 w-5" />
                  </button>
                </div>
                <ul className="space-y-2">
                  <li>
                    <button
                      onClick={() => handleFolderClick({ id: "all", name: "All Episodes" })}
                      className={classNames(
                        "w-full text-left px-2 py-1 rounded-md text-sm flex items-center space-x-2",
                        inboxState.currentFolder?.id === "all"
                          ? "bg-primary-50 text-primary-700"
                          : "text-neutral-700 hover:bg-neutral-50"
                      )}>
                      {getIconComponent("InboxIcon")}
                      <span>All Episodes</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFolderClick({ id: "starred", name: "Starred" })}
                      className={classNames(
                        "w-full text-left px-2 py-1 rounded-md text-sm flex items-center space-x-2",
                        inboxState.currentFolder?.id === "starred"
                          ? "bg-primary-50 text-primary-700"
                          : "text-neutral-700 hover:bg-neutral-50"
                      )}>
                      {getIconComponent("StarIcon")}
                      <span>Starred</span>
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-neutral-200 text-primary-800">
                        {LibraryData.episodes.filter((ep) => ep.is_starred).length}
                      </span>
                    </button>
                  </li>
                  {LibraryData.folders.map((folder) => (
                    <li key={folder.id}>
                      <div className="flex items-center justify-between group">
                        <button
                          onClick={() => handleFolderClick(folder)}
                          className={classNames(
                            "flex-grow text-left px-2 py-1 rounded-md text-sm flex items-center space-x-2",
                            inboxState.currentFolder?.id === folder.id
                              ? "bg-primary-50 text-primary-700"
                              : "text-neutral-700 hover:bg-neutral-50"
                          )}>
                          {getIconComponent(folder.icon || "FolderIcon")}
                          <span>{folder.name}</span>
                          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-neutral-200 text-primary-800">
                            {folder.episodes?.length || 0}
                          </span>
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setFolderToDelete(folder);
                            setIsDeleteFolderModalOpen(true);
                          }}
                          className="p-1 rounded-md text-neutral-400 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity">
                          <TrashIcon className="h-4 w-4" />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <nav
              aria-label="episode list"
              className="flex-1 overflow-y-auto p-1 mb-1 scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300"
              style={{ height: "calc(100vh - 180px)" }}>
              <ul className="divide-y">
                {inboxState.filteredEpisodeList.length === 0 ? (
                  <li className="py-4 px-4 text-center">
                    <p className="text-gray-500 mb-4">
                      {searchTerm
                        ? "No episodes match your search."
                        : props.isEmpty
                        ? "Your inbox is empty."
                        : "No episodes in this folder. Add an episode to folder using the command menu"}
                    </p>
                  </li>
                ) : (
                  inboxState.filteredEpisodeList.map((episode) => (
                    <li
                      key={episode.id}
                      onClick={() => handleEpisodeClick(episode)}
                      className={classNames(
                        "relative py-5 px-3 cursor-pointer transition-all duration-150 ease-in-out",
                        episode.id === inboxState.selectedEpisode?.id
                          ? "bg-primary-50 border-l-4 border-l-primary-600 hover:bg-primary-100"
                          : episode.isNew
                          ? "bg-white border-l-4 border-l-secondary-600 hover:bg-neutral-50"
                          : "bg-white border-l-4 border-transparent hover:bg-neutral-50 hover:border-l-4 hover:border-l-primary-200"
                      )}>
                      <div className="min-w-0 flex-1 flex flex-row space-x-3">
                        {multiSelectMode && (
                          <div
                            className="flex items-center mr-1"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            <input
                              type="checkbox"
                              checked={!!selectedEpisodes[episode.id]}
                              onChange={(e) => {
                                const isShiftKey = e.nativeEvent.shiftKey;
                                handleEpisodeSelection(episode, e.target.checked, isShiftKey);
                              }}
                              className="h-4 w-4 text-primary-600 focus:ring-primary-500 rounded border-neutral-300"
                            />
                          </div>
                        )}
                        <Img
                          src={episode.feedImage}
                          alt=""
                          className="w-10 h-10 rounded-lg object-cover text-grey-600 flex-shrink-0"
                        />

                        <div className="flex-1 min-w-0 space-y-1">
                          {/* Title Row */}
                          <div className="w-full">
                            {searchTerm ? (
                              <Highlight
                                text={episode.title}
                                highlight={searchTerm}
                                className="text-sm font-semibold text-primary-700 line-clamp-1"
                              />
                            ) : (
                              <p className="text-sm font-semibold text-primary-700 line-clamp-1">{episode.title}</p>
                            )}
                          </div>

                          {/* Podcast Title and Date Row */}
                          <div className="flex justify-between items-center">
                            <div className="min-w-0 flex-1">
                              {searchTerm ? (
                                <Highlight
                                  text={episode.podcast_title}
                                  highlight={searchTerm}
                                  className="text-xs text-secondary-700 line-clamp-1"
                                />
                              ) : (
                                <p className="text-xs text-secondary-700 line-clamp-1">{episode.podcast_title}</p>
                              )}
                            </div>
                            <time
                              dateTime={new Date(episode.datePublished * 1000).toISOString()}
                              className="ml-2 flex-shrink-0 whitespace-nowrap text-xs text-neutral-500">
                              {format(new Date(episode.datePublished * 1000), "MM/dd/yyyy")}
                            </time>
                          </div>

                          {/* Description Row */}
                          <p className="text-xs text-neutral-600 line-clamp-5">
                            {remove_html_tags(episode.description)}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </nav>
          </div>
        </div>
      </aside>
      <AddInboxContentModal show={isAddInboxContentModalOpen} onClose={() => setIsAddInboxContentModalOpen(false)} />
      <AddFolderModal
        show={isAddFolderModalOpen}
        onClose={() => setIsAddFolderModalOpen(false)}
        onSave={handleCreateFolder}
      />
      <DeleteFolderModal
        show={isDeleteFolderModalOpen}
        onClose={() => {
          setIsDeleteFolderModalOpen(false);
          setFolderToDelete(null);
        }}
        onConfirm={() => {
          if (folderToDelete) {
            handleDeleteFolder(folderToDelete.id);
            setIsDeleteFolderModalOpen(false);
            setFolderToDelete(null);
          }
        }}
        folderName={folderToDelete?.name}
      />
      <BatchFolderModal
        show={isBatchFolderModalOpen}
        onClose={() => setIsBatchFolderModalOpen(false)}
        selectedEpisodeIds={Object.keys(selectedEpisodes).filter((id) => selectedEpisodes[id])}
        onSuccess={handleClearSelection}
      />
      <BatchPodscribeModal
        show={batchPodscribeModal.open}
        onClose={() => setBatchPodscribeModal({ open: false, episodeIds: [], totalMinutes: 0 })}
        totalEpisodes={batchPodscribeModal.episodeIds.length}
        totalMinutes={batchPodscribeModal.totalMinutes}
        onConfirm={async () => {
          try {
            // Create batch requests for each episode
            const batchRequests = batchPodscribeModal.episodeIds.map((episodeId) => ({
              id: `transcribe-${episodeId}`,
              method: "POST",
              url: `/podcast/transcription/${episodeId}`,
            }));

            // Execute batch request
            const result = await executeBatch(batchRequests);
            
            if (result.success) {
              // Update the is_processing flag in LibraryData state
              setLibraryData((prevData) => ({
                ...prevData,
                episodes: prevData.episodes.map((episode) => {
                  if (batchPodscribeModal.episodeIds.includes(String(episode.id))) {
                    return {
                      ...episode,
                      is_processing: true // Same property used in single episode processing
                    };
                  }
                  return episode;
                }),
              }));
              
              toast.success(`Processing started for ${batchPodscribeModal.episodeIds.length} episodes`);
              handleClearSelection();
            } else {
              toast.error("Failed to start batch processing");
            }
          } catch (error) {
            console.error("Batch processing error:", error);
            toast.error("Failed to start batch processing");
          }
          setBatchPodscribeModal({ open: false, episodeIds: [], totalMinutes: 0 });
        }}
      />
      <BatchExportModal
        show={isExportModalOpen}
        totalEpisodes={Object.keys(selectedEpisodes).filter((id) => selectedEpisodes[id]).length}
        onClose={() => setIsExportModalOpen(false)}
        onConfirm={executeExport}
        isProcessing={exportProcessing}
      />
    </>
  );
}
