import React, { useEffect, useState } from "react";
import Button from "../../UI/Button";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import { axiosPrivate } from "../../common/axiosPrivate";
import { Banner } from "../../UI/Banner";
import { UserRemoveIcon, GiftIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import ActionModalDanger from "../../UI/ActionModalDanger";
import { FadeLoader } from "react-spinners";
import { toast } from "react-hot-toast";
import PaddleScript from "./PaddleScript";
import { Helmet } from "react-helmet";
import SEO from "../SEO";
export default function Profile(props) {
  const [userEmail, setUserEmail] = React.useState("");
  const { auth, setAuth, refreshUserData } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmitChangePassword = (data) => {
    // update user profile
    updatePassword(data);
  };

  // Fetch user profile information using useAuth hook

  useEffect(() => {
    refreshUserData();
  }, []);

  const handleUpdatePaymentMethod = async () => {
    try {
      const res = await axiosPrivate.post("/payment/create-checkout-session/update-payment-method");
      if (res.data) {
        const Paddle = window.Paddle;
        Paddle.Checkout.open({
          settings: {
            locale: "en",
            allowLogout: false,
          },
          transactionId: res.data.transaction_id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async () => {
    try {
      await axiosPrivate.delete("/users/me");
      setShowModal(false);
      navigate("/login", { replace: true });
    } catch (error) {
      toast.error("There was an error deleting your account. Please try again.");
      console.log(error);
    }
  };

  const updatePassword = async (data) => {
    try {
      console.log(data);
      await axiosPrivate.post("/auth/password-reset", {
        new_password: data.password,
        repeated_password: data.confirmPassword,
      });
      toast.success("Password updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("There was an error updating your password. Please try again.");
    }
  };

  const calculateNextBillingDate = () => {
    const today = new Date();
    const nextBillingDate = new Date(auth.user.subscription_period_end);
    const cancelBillingDate = new Date(auth.user.subscription_scheduled_changes?.effective_at);
    if (auth.user.subscription_scheduled_changes?.action === "cancel") {
      return (
        <>
          will be canceled at{" "}
          <strong>
            {cancelBillingDate.toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </strong>
        </>
      );
    }

    if (today > nextBillingDate) {
      return "Not Applicable";
    } else {
      return (
        <>
          will renew at{" "}
          <strong>
            {nextBillingDate.toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </strong>
        </>
      );
    }
  };

  return (
    <>
      <SEO title="PodScribe.IO | User Profile" />
      <div className="flex-1 flex flex-col h-full pb-10 bg-white lg:mt-3 lg:ml-3 lg:mr-3 lg:rounded-t-lg border border-neutral-100 shadow-sm">
        <div className="bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
          {/* Page Header */}
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div>
              <h1 className="text-2xl font-bold text-primary-600">User Profile</h1>
              <p className="text-sm text-neutral-500 mt-1">
                Please contact us at{" "}
                <a
                  href="mailto:info@podscribe.io"
                  className="font-medium underline text-primary-600 hover:text-primary-800">
                  info@podscribe.io
                </a>{" "}
                for any assistance.
              </p>
            </div>
          </div>
        </div>

        {/* User settings Form */}
        <div className="flex-1 flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-200 scrollbar-track-transparent hover:scrollbar-thumb-neutral-300">
          <div className="w-full max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="flex flex-col space-y-8">
              <div className="bg-white p-6 rounded-lg border border-neutral-100 shadow-sm w-full transition-shadow hover:shadow-md">
                <h2 className="text-xl font-semibold text-primary-600 mb-6">User Settings</h2>
                {/* Form inputs */}
                <form onSubmit={handleSubmit(onSubmitChangePassword)} className="space-y-6 w-full max-w-md">
                  <div className="w-full">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-neutral-900">
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full rounded-md border-0 py-2.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                        placeholder={auth.user.name}
                        autoComplete="name"
                        disabled
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-neutral-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-0 py-2.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                        placeholder={auth.user.email}
                        autoComplete="email"
                        disabled
                        readOnly
                      />
                      {errors.password && <p className="text-support-red-500">{errors.password.message}</p>}
                    </div>
                  </div>
                  <div className="w-full">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-neutral-900">
                      New Password
                    </label>
                    <div className="mt-2">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="block w-full rounded-md border-0 py-2.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                        placeholder="********"
                        autoComplete="new-password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "Password must be at least 8 characters long",
                          },
                          pattern: {
                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                            message: "Password must contain at least one letter and one number",
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-neutral-900">
                      Repeat new Password
                    </label>
                    <div className="mt-2">
                      <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        className="block w-full rounded-md border-0 py-2.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                        placeholder="********"
                        autoComplete="new-password"
                        {...register("confirmPassword", {
                          required: true,
                          validate: (value) => value === watch("password"),
                        })}
                      />
                      {errors.confirmPassword && <p className="text-support-red-500">Passwords do not match</p>}
                    </div>
                  </div>
                  <div className="flex justify-end items-end pt-4">
                    <Button type="submit" className="w-full sm:w-auto">
                      Update Password
                    </Button>
                  </div>
                </form>
              </div>

              <div className="bg-white p-6 rounded-lg border border-neutral-100 shadow-sm w-full transition-shadow hover:shadow-md">
                <h2 className="text-xl font-semibold text-primary-600 mb-6">Manage Billing</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm text-neutral-900">
                  <span className="font-semibold">Subscription Status: </span>
                  <span className="font-bold text-secondary-600">
                    {auth.user.subscription_status === null ? "Free" : auth.user.subscription_status}
                  </span>

                  <span className="font-semibold">Subscription Plan: </span>
                  <span className="font-bold text-secondary-600">
                    {auth.user.subscription_tier === null ? "Free" : auth.user.subscription_tier}
                  </span>

                  <span className="font-semibold">Billing Cycle: </span>
                  <span className="font-bold text-secondary-600">{auth.user.subscription_frequency}</span>

                  {/* Add divider */}
                  <div className="col-span-2 border-t border-neutral-200 my-2"></div>

                  {/* Add AI Messages section */}
                  <span className="font-semibold">Available AI Messages from subscription: </span>
                  <span className="font-bold text-secondary-600">{auth.user.ai_messages}</span>

                  <span className="font-semibold">Additional AI Messages from Top-up: </span>
                  <span className="font-bold text-secondary-600">{auth.user.ai_topup_messages}</span>

                  {/* Add divider */}
                  <div className="col-span-2 border-t border-neutral-200 my-2"></div>

                  <span className="font-semibold">Available AI processing (minutes) from subscription: </span>
                  <span className="font-bold text-secondary-600">{auth.user.podscribe_minutes}</span>

                  <span className="font-semibold">Additional AI processing (minutes) from Top-up: </span>
                  <span className="font-bold text-secondary-600">{auth.user.podscribe_topup_minutes}</span>
                </div>
                {auth.user?.subscription_status === null ? (
                  <p className="text-sm leading-5 text-neutral-500 mt-5">
                    Please subscribe to a plan to access more features.
                  </p>
                ) : (
                  <>
                    <p className="text-sm leading-5 text-neutral-500 mt-5">
                      Your current subscription will {calculateNextBillingDate()}.
                    </p>
                    <p className="text-sm leading-5 text-neutral-500 mt-5">
                      Need more minutes?{" "}
                      <Link to="/subscription" className="text-primary-600 hover:text-primary-800 font-medium">
                        Top up your account
                      </Link>{" "}
                      with our Topup package.
                    </p>

                    <p className="text-sm leading-5 text-neutral-500 mt-5">
                      You can manage your subscription from the options below.
                    </p>
                    <div className="flex justify-end items-end">
                      <span className="isolate rounded-md items-end shadow-sm mt-5 flex flex-col sm:flex-row sm:space-x-5">
                        <Button className="mt-5" onClick={handleUpdatePaymentMethod}>
                          Update Payment Method
                        </Button>
                        <Link to="/subscription/cancel">
                          <Button className="mt-5 bg-support-red-500 hover:bg-support-red-800">
                            Cancel Subscription
                          </Button>
                        </Link>
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div className="bg-white p-6 rounded-lg border border-neutral-100 shadow-sm w-full transition-shadow hover:shadow-md">
                <h2 className="text-xl font-semibold text-primary-600 mb-6">Delete Account</h2>
                <p className="text-sm text-neutral-500 mb-4">
                  Once you delete your account, there is no going back. Please be certain.
                </p>
                <div className="flex justify-end">
                  <Button
                    type="button"
                    className="bg-support-red-500 hover:bg-support-red-800"
                    onClick={() => setShowModal(true)}>
                    Delete Account
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaddleScript />
      <ActionModalDanger
        show={showModal}
        title="Delete Account"
        BodyText="Are you sure you want to delete your account? This action cannot be undone."
        CloseButtonText="Cancel"
        ActionButtonText="Delete"
        HandleOnClose={() => setShowModal(false)}
        HandleOnAction={deleteUser}
      />
    </>
  );
}
